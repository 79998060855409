// import { spawn, Worker } from "threads/dist";
// import { CoreThread } from "../core/core-thread";
// import { Container, Inject, Singleton } from "typescript-ioc";
// import { WorkerImplementation } from "threads/dist/types/master";
// import { MainControllerComponent } from "./controller/main-controller.component";
// import { MainKeyboardControllerComponent } from "./controller/main-keyboard-controller.component";
// import { GuiManager } from "./gui/gui.manager";
// import { GuiComponent } from "./gui/gui.component";
import * as THREE from "three";
import { RendererComponent } from "./../core/renderer/renderer.component";
import { SceneComponent } from "../core/scene/scene.component";
import { CameraComponent } from "../core/camera/camera.component";
import { PhysicsComponent } from "../core/physics/physics.component";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
export default class Main {
  // private canvas: HTMLCanvasElement;
  // private coreThread?: CoreThread;

  constructor(
    renderer,
    refSceneWestern,
    refSceneCity,
    receiveCamera,
    receivePortalRoom
  ) {
    // this.canvas = null;
    this.canvas = renderer.domElement;
    this.coreThread = null;
    this.portalCooldown = false;
    this.minimumPortalDistance = 2;
    // this.initCanvas();
    // this.initCoreThread();
    // this.initComponents();
    // console.log("Main thread OK");
    this.rendererComponent = new RendererComponent(
      renderer,
      refSceneWestern,
      refSceneCity,
      receivePortalRoom
    );

    console.log("Portal en main", this.portalRoom);

    const canvasDiv = document.getElementById("canvas-div");
    this.rendererComponent.init();
    // this.rendererComponent.init(canvasDiv);
    this.camera = new CameraComponent();
    this.camera = this.camera.getCamera();
    this.camera.aspect = window.innerWidth / window.innerHeight;
    // refCamera.current = this.camera;
    receiveCamera(this.camera);

    // const controls = new OrbitControls(this.camera, renderer.domElement);
    // this.camera = refCamera.current;
    this.previousPosition = new THREE.Vector3();
    this.scene = new SceneComponent();
    const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
    const boxMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    this.box = new THREE.Mesh(boxGeometry, boxMaterial);
    // this.scene.add(this.box);
    this.currentWorld = this.scene.getCurrentWorld();
    this.physics = new PhysicsComponent(this.scene);
    this.position = new THREE.Vector3(0, 1, 2);

    this.roomWorld = this.scene.getRoomWorld();
    this.skyWorld = this.scene.getSkyWorld();
    // console.log("skyWorld", this.skyWorld);

    this.animate = this.animate.bind(this);
    this.animate();
    this.eventListener = this.eventListener.bind(this);
    this.eventListener();
    this.resize = this.resize.bind(this);
    this.resize();
  }
  getPortalRoom() {
    return this.portalRoom;
  }
  eventListener() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "l") {
        this.scene.setCurrentWorld(this.skyWorld);
        // console.log("camera", this.camera);
      }
      if (e.key === "o") {
        this.scene.setCurrentWorld(this.roomWorld);
        // console.log("camera", this.camera);
      }
    });
  }
  setWorld(newWorld) {
    if (this.currentWorld !== newWorld) {
      this.scene.setCurrentWorld(newWorld);
      this.currentWorld = newWorld; // Actualizar el estado del mundo actual
    }
  }

  resize() {
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.rendererComponent.setSize(width, height);
    });
  }
  // limitWorldSize() {
  //   const size = this.scene.getCurrentWorld().getSize();
  //   this.position.x = MathUtil.minMax(this.position.x, -size, size);
  //   this.position.z = MathUtil.minMax(this.position.z, -size, size);
  // }
  animate() {
    this.rendererComponent.render(
      this.scene.getWorlds(),
      this.scene.getCurrentWorld(),
      // this.camera.getCamera()
      this.camera
    );
    // const scenes = Array.from(this.scene.getWorlds());
    // console.log("scenes");
    // const scene1 = scenes[0][1].group;
    // const scene2 = scenes[1][1].group;
    // scene1.add(this.box);
    // scene2.add(this.box);

    /**
     *
     *
     *
     * Base
     *
     */
    // const position = this.camera.getCamera().position;
    // const movement = new THREE.Vector3();
    // this.camera.getCamera().getWorldDirection(movement);

    // const collision = this.physics.checkPortalCollision(position, movement);

    // if (collision?.object?.destinationPortalName.includes("SkyWorld")) {
    //   this.scene.setCurrentWorld(this.skyWorld);
    // } else if (collision?.object?.destinationPortalName.includes("Room")) {
    //   this.scene.setCurrentWorld(this.roomWorld);
    // }
    /**
     *
     *
     * Fin base
     *
     */
    /**
     *
     * Segunda prueba
     *
     */
    if (!this.box) return;

    // this.camera.getCamera().updateMatrixWorld(true);
    // this.camera.updateMatrixWorld(true);
    // const currentPosition = this.camera.getCamera().position.clone();
    const currentPosition = this.camera.position.clone();
    const vectorWorldDirection = new THREE.Vector3();
    // this.camera.getCamera().getWorldDirection(vectorWorldDirection);
    this.camera.getWorldDirection(vectorWorldDirection);
    if (vectorWorldDirection.z < 0) {
      currentPosition.z -= 0.1;
    } else if (vectorWorldDirection.z > 0) {
      // currentPosition.z += 0.05;
      currentPosition.z += 0.2;
    }
    // this.camera.getCamera().rotation.order = "YXZ";
    const movement = new THREE.Vector3().subVectors(
      currentPosition,
      this.previousPosition
    );
    // movement.multiplyScalar(0.05).multiplyScalar(0.016);
    // movement.normalize();
    const collision = this.physics.checkPortalCollision(
      currentPosition,
      movement
    );
    if (collision?.object?.destinationPortalName.includes("SkyWorld")) {
      // console.log("collision", collision);
      this.scene.setCurrentWorld(this.skyWorld);
    } else if (collision?.object?.destinationPortalName.includes("Room")) {
      this.scene.setCurrentWorld(this.roomWorld);
    }
    // if (collision) {
    //   const destinationWorld = collision.object.destinationPortalName.includes(
    //     "SkyWorld"
    //   )
    //     ? this.skyWorld
    //     : this.roomWorld;

    //   if (this.currentWorld !== destinationWorld) {
    //     setTimeout(() => {
    //       this.setWorld(destinationWorld);
    //     }, 50); // Ajusta el tiempo de retraso según sea necesario
    //   }
    // }
    // }

    // this.camera.getCamera().updateWorldMatrix();
    // this.camera.getCamera().updateMatrixWorld();
    // Actualizar la posición anterior para el siguiente fotograma
    // currentPosition.multiplyScalar(collision?.ratio);
    this.position.add(movement);
    this.previousPosition.copy(currentPosition);
    /**
     *
     * Tercera prueba
     *
     */

    // if (
    //   !collision &&
    //   collision2?.object?.destinationPortalName.includes("SkyWorld")
    // ) {
    //   this.scene.setCurrentWorld(this.skyWorld);
    // } else if (
    //   !collision &&
    //   collision2?.object?.destinationPortalName.includes("Room")
    // ) {
    //   this.scene.setCurrentWorld(this.roomWorld);
    // }
    // const currentPosition = this.camera.getCamera().position.clone();
    // const movement = new THREE.Vector3().subVectors(
    //   currentPosition,
    //   this.previousPosition
    // );
    // const collision = this.physics.checkPortalCollision(
    //   currentPosition,
    //   movement
    // );

    // if (collision?.object?.destinationPortalName.includes("Room")) {
    //   // setTimeout(() => {
    //   this.setWorld(this.roomWorld);

    //   console.log("collision", collision);

    //   // }, 50);
    // } else if (collision?.object?.destinationPortalName.includes("SkyWorld")) {
    //   // setTimeout(() => {
    //   this.setWorld(this.skyWorld);
    //   console.log("collision", collision);
    //   // }, 50);
    // }

    // Actualizar la posición anterior
    // this.previousPosition.copy(currentPosition);
    requestAnimationFrame(this.animate);
  }

  // async initThread(worker) {
  //   const thread = await spawn(worker);
  //   return [thread, worker];
  // }

  // initCanvas() {
  //   this.canvas = document.createElement("canvas");
  //   document.body.appendChild(this.canvas);
  // }

  initCoreThread() {
    // const offscreenCanvas = this.canvas.transferControlToOffscreen();
    // offscreenCanvas.width = this.canvas.clientWidth;
    // offscreenCanvas.height = this.canvas.clientHeight;
    // this.initThread <
    //   CoreThread >
    //   new Worker("../core/core-thread").then(([coreThread, coreWorker]) => {
    //     this.coreThread = coreThread;
    //     console.log("Core thread created");
    //     coreWorker.postMessage({ canvas: offscreenCanvas }, [offscreenCanvas]);
    //   });
  }

  initComponents() {
    // this.controller.init(this.canvas, this.guiComponent.getLayer());
    // this.controller.mouseMove$.subscribe((event) =>
    //   this.coreThread?.mouseMove(event.x, event.y)
    // );
    // this.controller.resize$.subscribe(() =>
    //   this.coreThread?.setSize(window.innerWidth, window.innerHeight)
    // );
    // this.controller.pointerLock$.subscribe((status) =>
    //   this.coreThread?.setPointerLock(status)
    // );
    // this.keyboardController.key$.subscribe((keyEvent) =>
    //   this.coreThread?.keyEvent(keyEvent)
    // );
  }
}

// Container.get(Main);
