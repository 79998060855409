import { PerspectiveCamera, Quaternion, Vector3 } from "three";
import gsap from "gsap";
export class CameraComponent {
  camera;

  constructor() {
    this.camera = new PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.0001,
      1000000
    );
    // this.camera.matrixAutoUpdate = false;
    //Configuracion para inicar camara animcion
    // this.camera.position.z = -100;
    // this.camera.position.x = -92.9;
    // this.camera.rotation.y = Math.PI * 1.25;
    // this.camera.position.set(0, 1000, 10);
    // this.camera.far = 3000000;
    // this.camera.near = 0.0001;
    // this.camera.lookAt(0, 1000, 0);
    // this.camera.position.z = 1200;

    /**
     *
     * Para editar
     */
    // this.camera.position.z = -20;
    // this.camera.position.x = -17.5;

    this.eventListener = this.eventListener.bind(this);
    this.eventListener();
  }
  eventListener() {
    // window.addEventListener("keydown", (e) => {
    //   if (e.key === "c") {
    //     gsap.to(this.camera.position, {
    //       z: 0,
    //       x: 0,
    //       duration: 2,
    //       delay: 1,
    //       onComplete: () => {
    //         gsap.to(this.camera.position, {
    //           z: 20,
    //           x: 20,
    //         });
    //         // console.log("posicion camara", this.camera.position);
    //       },
    //     });
    //   }
    // });
  }

  getCamera() {
    return this.camera;
  }

  setAspectRatio(ratio) {
    this.camera.aspect = ratio;
    this.camera.updateProjectionMatrix();
  }

  // setQuaternion(quaternion, t) {
  //   this.camera.quaternion.slerp(quaternion, t);
  // }

  // setPosition(position) {
  //   this.camera.position.copy(position);
  // }
}
