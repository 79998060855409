// import { Object3D } from "three";
// import { WorldObject } from "./world-object";

export class AbstractObject {
  group;

  getGroup() {
    return this.group;
  }

  add(object) {
    this.group.add(object);
  }

  addObject(object) {
    this.group.add(object.getGroup());
  }

  remove(object) {
    this.group.remove(object);
  }

  step(delta) {}
}
