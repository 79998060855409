// import { Singleton } from "typescript-ioc";
import { SkyWorld } from "./instance/sky-world";
// import { World } from "./instance/world";
// import { PortalWorldObject } from "../object/portal-world-object";
import { RoomWorld } from "./instance/room-world";
import * as THREE from "three";

// import TWEEN from '@tweenjs/tween.js';

// @Singleton
export class SceneComponent {
  worlds = new Map();
  currentWorld;

  constructor() {
    this.roomWorld = new RoomWorld();
    this.skyWorld = new SkyWorld();
    // this.add(new THREE.AmbientLight(0x222222));
    this.add(this.roomWorld);
    this.add(this.skyWorld);
    // console.log("this.currentWorld", this.currentWorld);
    this.currentWorld = this.roomWorld;
    //this.add(new BoxWorld1());
    //this.add(new BoxWorld2());
    //this.add(new BoxWorld3());
    //this.add(new BoxWorld4());
    // this.step = this.step.bind(this);
    // setTimeout(() => {
    //   this.step(0.016);
    // }, 3000);
  }
  getRoomWorld() {
    return this.roomWorld;
  }
  getSkyWorld() {
    return this.skyWorld;
  }

  getCurrentWorld() {
    return this.currentWorld;
  }

  setCurrentWorld(world) {
    this.currentWorld = world;
  }

  getWorld(name) {
    return this.worlds.get(name);
  }

  getWorlds() {
    return this.worlds;
  }

  step(delta) {
    // Array.from(this.worlds.values()).forEach((scene) => scene.step(delta));
    // Array.from(this.worlds.values()).forEach((scene) => console.log(scene));
    // TWEEN.update();
    // requestAnimationFrame(this.step(0.016));
  }

  getPortals() {
    const portals = new Map();
    Array.from(this.worlds.values()).forEach((scene) =>
      scene
        .getPortals()
        .forEach((portal) => portals.set(portal.getName(), portal))
    );
    return portals;
  }
  getAllPortals() {
    // const portals = new Map<string, PortalWorldObject>();
    // Array.from(this.worlds.values()).forEach(scene => scene.getPortals().forEach(portal => portals.set(portal.getName(), portal)));
    return this.worlds.values();
  }

  add(world) {
    this.worlds.set(world.getName(), world);
    this.updatePortals();
  }

  updatePortals() {
    const portals = this.getPortals();
    Array.from(portals.values()).forEach((portal) => {
      if (!portal.getDestination()) {
        if (portals.has(portal.getDestinationPortalName())) {
          const otherPortal = portals.get(portal.getDestinationPortalName());
          portal.setDestination(otherPortal);
          otherPortal.setDestination(portal);
        }
      }
    });
  }
}
