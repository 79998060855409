import "./App.css";
import { Leva } from "leva";
import Experience from "./components/Experience";
import { contextGeneral } from "./context/contextGeneral";

function App() {
  return (
    <contextGeneral.Provider value={{}}>
      <div style={{ width: "100%", height: "100vh" }}>
        <Leva hidden />
        <Experience />
      </div>
    </contextGeneral.Provider>
  );
}

export default App;
