import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import EnvironmentJet from "./EnvironmentJet";
import Jet from "./Jet";
import { Environment } from "@react-three/drei";
import LensFlare from "../../lensflare/lensflare";
import { EffectComposer } from "@react-three/postprocessing";
import CloudsJet from "./CloudsJet";
import { Jet3 } from "./Jet3";

const SceneJet = ({ refSceneCity }) => {
  const refSceneJet = useRef();

  return (
    <>
      {/* <EnvironmentJet refSceneCity={refSceneCity} /> */}
      {/* value: { x: -25, y: 6, z: -60 }, */}

      <group position={[0, 1480, 0]}>
        <Jet />
        {/* <Jet3 /> */}
        <CloudsJet />
      </group>
    </>
  );
};

export default SceneJet;
