import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

// import vertexShader from "./shaders/vertex.js/index.js";
// import fragmentShader from "./shaders/fragment.glsl";

// import simFragment from "./shaders/simFragment.js";
// import simVertex from "./shaders/simVertex.glsl";

// import t1 from "../logo.png";

// import bird from "../bird.glb?url";
import { simVertex } from "./shaders/simVertex";
import { simFragment } from "./shaders/simFragment";
import { vertexShader } from "./shaders/vertex";
import { fragmentShader } from "./shaders/fragment";
import { useEffect, useRef } from "react";
import { useThree } from "@react-three/fiber";
import gsap from "gsap";
import { animationTimePortal } from "./../../../../../constants/globalConstants";

function lerp(a, b, n) {
  return (1 - n) * a + n * b;
}

const loadImage = (path) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // to avoid CORS if used with Canvas
    img.src = path;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (e) => {
      reject(e);
    };
  });
};
const Ring = ({
  position,
  ring,
  scale = 17,
  saveRing,
  startAnimation = false,
}) => {
  const { scene, gl } = useThree();
  const refGroup = useRef(null);
  const refEmitters = useRef([]);
  const refMeshBox = useRef(null);
  const refMeshBox2 = useRef(null);
  const refMeshBox3 = useRef(null);
  const refMeshBox4 = useRef(null);
  const refMesh = useRef(null);
  const refGroupPrincipal = useRef(null);

  class Sketch {
    constructor() {
      this.init = false;
      this.v = new THREE.Vector3(0, 0, 0);
      this.currentParticles = 0;
      this.size = 96;
      this.number = this.size * this.size;
      //   this.container = options.dom;
      //   this.scene = new THREE.Scene();
      // console.log("scene en ring es", scene);

      this.scene = refGroupPrincipal.current;
      // this.scene = scene;

      this.width = gl.domElement.offsetWidth;
      this.height = gl.domElement.offsetHeight;

      this.raycaster = new THREE.Raycaster();
      this.pointer = new THREE.Vector2();

      //   this.renderer = new THREE.WebGLRenderer({
      //     alpha: true,
      //     antialias: true,
      //   });
      //   this.renderer.setClearColor(0x222222, 1);
      //   this.renderer.setSize(this.width, this.height);
      //   this.container.appendChild(this.renderer.domElement);
      this.renderer = gl;
      this.camera = new THREE.PerspectiveCamera(
        70,
        this.width / this.height,
        0.01,
        10
      );
      this.camera.position.z = 2;

      // this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.loader = new GLTFLoader();

      this.time = 0;
      this.setupSettings();
      this.emitters = [];
      this.group = new THREE.Group();
      Promise.all([
        // this.getPixelDataFromImage(t1),
        // this.getPixelDataFromImage(t2),
        // this.loader.loadAsync(bird),
      ]).then(([model]) => {
        const geometry = new THREE.TorusGeometry(10, 0.46, 16, 100);
        const material = new THREE.MeshMatcapMaterial({ color: 0xffff00 });
        const mesh = new THREE.Mesh(geometry, material);
        // ring = mesh;
        const geometryBox = new THREE.BoxGeometry(0.0001, 0.0001, 0.0001);
        const materialBox = new THREE.MeshBasicMaterial({
          color: 0xff0000,
          transparent: true,
          opacity: 0,
        });
        const meshBox = new THREE.Mesh(geometryBox, materialBox);
        meshBox.position.set(0.4, 0, 0);
        this.group.add(mesh);
        const meshBox2 = meshBox.clone();
        meshBox2.position.set(-0.4, 0, 0);
        const meshBox3 = meshBox.clone();
        meshBox3.position.set(0, 0.4, 0);
        const meshBox4 = meshBox.clone();
        meshBox4.position.set(0, -0.4, 0);
        const meshBox5 = meshBox.clone();
        meshBox5.position.set(0.35, 0.2, 0);
        const meshBox6 = meshBox.clone();
        meshBox5.position.set(-0.35, 0.2, 0);
        this.group.add(meshBox);
        this.group.add(meshBox2);
        this.group.add(meshBox3);
        this.group.add(meshBox4);
        this.group.add(meshBox5);
        this.group.add(meshBox6);
        // this.group.position.set(position.x, position.y, position.z - 2.03);
        // this.group.position.set(position.x + 2, position.y, position.z);
        this.group.rotation.y = -Math.PI * 0.75;
        // this.group.position.x -= 1.7;
        // this.group.position.z -= 1.9;
        this.group.position.y = 1.76;
        // mesh.scale.set(scale * 0.05, scale * 0.05, scale * 0.05);
        // mesh.scale.set(0.05, 0.05, 0.05);
        mesh.scale.set(0.04, 0.04, 0.04);
        this.scene.add(this.group);
        this.group.scale.set(0, 0, 0);
        console.log("1");

        refGroup.current = this.group;
        refMeshBox.current = meshBox;
        refMeshBox2.current = meshBox2;
        refMeshBox3.current = meshBox3;
        refMeshBox4.current = meshBox4;
        // ring = this.group;
        // saveRing(this.group);

        // this.model = model.scene;
        // this.model.visible = false;
        // this.scene.add(this.model);

        window.addEventListener("keydown", (e) => {
          if (e.key === "2") {
            console.log("Ring, this.group", this.group);
            refMesh.current.visible = true;
            refMeshBox.current.visible = true;
            refMeshBox2.current.visible = true;
            refMeshBox3.current.visible = true;
            refMeshBox4.current.visible = true;
            this.group.scale.set(17, 17, 17);
          } else if (e.key === "k") {
            this.group.scale.set(0.5, 0.5, 0.5);
          }
        });

        // this.model.traverse((m) => {
        //   if (m.isMesh && m.name.includes("emitter")) {
        //     // this.emitters.push({
        //     //   mesh: m,
        //     //   prev: m.position.clone(),
        //     //   dir: new THREE.Vector3(0, 0, 0),
        //     // });

        //     m.visible = false;
        //     m.material = new THREE.MeshBasicMaterial({
        //       color: 0xff0000,
        //     });
        //   }
        // });
        this.emitters.push({
          mesh: meshBox,
          prev: meshBox.position.clone(),
          dir: new THREE.Vector3(0, 0, 0),
        });
        this.emitters.push({
          mesh: meshBox2,
          prev: meshBox2.position.clone(),
          dir: new THREE.Vector3(0, 0, 0),
        });
        this.emitters.push({
          mesh: meshBox3,
          prev: meshBox3.position.clone(),
          dir: new THREE.Vector3(0, 0, 0),
        });
        this.emitters.push({
          mesh: meshBox4,
          prev: meshBox4.position.clone(),
          dir: new THREE.Vector3(0, 0, 0),
        });
        // this.emitters.push({
        //   mesh: meshBox5,
        //   prev: meshBox5.position.clone(),
        //   dir: new THREE.Vector3(0, 0, 0),
        // });
        // this.emitters.push({
        //   mesh: meshBox6,
        //   prev: meshBox6.position.clone(),
        //   dir: new THREE.Vector3(0, 0, 0),
        // });
        meshBox.visible = false;
        meshBox2.visible = false;
        meshBox3.visible = false;
        meshBox4.visible = false;
        meshBox5.visible = false;
        refEmitters.current = this.emitters;
        // this.mixer = new THREE.AnimationMixer(this.model);
        // this.mixer.clipAction(model.animations[0]).play();

        this.data1 = this.getPointsOnSphere();
        this.data2 = this.getPointsOnSphere();
        // this.getPixelDataFromImage(t1);
        this.mouseEvents();
        this.setupFBO();
        this.addObjects();
        this.setupResize();
        this.render();
      });
    }

    setupSettings() {
      this.settings = {
        progress: 0,
      };

      //   this.gui = new GUI();
      //   this.gui.add(this.settings, "progress", 0, 1, 0.01).onChange((val) => {
      //     this.simMaterial.uniforms.uProgress.value = val;
      //   });
    }

    getPointsOnSphere() {
      const data = new Float32Array(4 * this.number);
      for (let i = 0; i < this.size; i++) {
        for (let j = 0; j < this.size; j++) {
          const index = i * this.size + j;

          // generate point on a sphere
          let theta = Math.random() * Math.PI * 2;
          let phi = Math.acos(Math.random() * 2 - 1); //
          // let phi = Math.random()*Math.PI; //
          let x = Math.sin(phi) * Math.cos(theta);
          let y = Math.sin(phi) * Math.sin(theta);
          let z = Math.cos(phi);

          data[4 * index] = x;
          data[4 * index + 1] = y;
          data[4 * index + 2] = z;
          data[4 * index + 3] = (Math.random() - 0.5) * 0.01;
        }
      }

      let dataTexture = new THREE.DataTexture(
        data,
        this.size,
        this.size,
        THREE.RGBAFormat,
        THREE.FloatType
      );
      dataTexture.needsUpdate = true;

      return dataTexture;
    }

    async getPixelDataFromImage(url) {
      let img = await loadImage(url);
      let width = 200;
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = width;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, width);
      let canvasData = ctx.getImageData(0, 0, width, width).data;

      let pixels = [];
      for (let i = 0; i < canvasData.length; i += 4) {
        let x = (i / 4) % width;
        let y = Math.floor(i / 4 / width);
        if (canvasData[i] < 5) {
          pixels.push({ x: x / width - 0.5, y: 0.5 - y / width });
        }
      }

      const data = new Float32Array(4 * this.number);
      for (let i = 0; i < this.size; i++) {
        for (let j = 0; j < this.size; j++) {
          const index = i * this.size + j;
          let randomPixel = pixels[Math.floor(Math.random() * pixels.length)];
          if (Math.random() > 0.9) {
            randomPixel = {
              x: 3 * (Math.random() - 0.5),
              y: 3 * (Math.random() - 0.5),
            };
          }
          data[4 * index] = randomPixel.x + (Math.random() - 0.5) * 0.01;
          data[4 * index + 1] = randomPixel.y + (Math.random() - 0.5) * 0.01;
          data[4 * index + 2] = (Math.random() - 0.5) * 0.01;
          data[4 * index + 3] = (Math.random() - 0.5) * 0.01;
        }
      }

      let dataTexture = new THREE.DataTexture(
        data,
        this.size,
        this.size,
        THREE.RGBAFormat,
        THREE.FloatType
      );
      dataTexture.needsUpdate = true;

      return dataTexture;
    }

    mouseEvents() {
      this.planeMesh = new THREE.Mesh(
        new THREE.SphereGeometry(1, 30, 30),
        new THREE.MeshBasicMaterial()
      );
      // this.dummy = new THREE.Mesh(
      //   new THREE.SphereGeometry(0.01, 32, 32),
      //   new THREE.MeshNormalMaterial()
      // );
      // this.scene.add(this.dummy);
      window.addEventListener("mousemove", (e) => {
        this.pointer.x = (e.clientX / this.width) * 2 - 1;
        this.pointer.y = -(e.clientY / this.height) * 2 + 1;
        this.raycaster.setFromCamera(this.pointer, this.camera);

        const intersects = this.raycaster.intersectObjects([this.planeMesh]);
        if (intersects.length > 0) {
          //   console.log(intersects[0].point);
          this.dummy.position.copy(intersects[0].point);
          this.simMaterial.uniforms.uMouse.value = intersects[0].point;
        }
      });
    }

    setupResize() {
      // window.addEventListener("resize", this.resize.bind(this));
    }

    setupFBO() {
      // create data Texture
      const data = new Float32Array(4 * this.number);
      for (let i = 0; i < this.size; i++) {
        for (let j = 0; j < this.size; j++) {
          const index = i * this.size + j;
          data[4 * index] = lerp(-0.5, 0.5, j / (this.size - 1));
          data[4 * index + 1] = lerp(-0.5, 0.5, i / (this.size - 1));
          data[4 * index + 2] = 0;
          data[4 * index + 3] = 1;
        }
      }

      this.positions = new THREE.DataTexture(
        data,
        this.size,
        this.size,
        THREE.RGBAFormat,
        THREE.FloatType
      );
      this.positions.needsUpdate = true;

      // create FBO scene
      this.sceneFBO = new THREE.Scene();
      let viewArea = this.size / 2 + 0.01;
      this.cameraFBO = new THREE.OrthographicCamera(
        -viewArea,
        viewArea,
        viewArea,
        -viewArea,
        -2,
        2
      );
      this.cameraFBO.position.z = 1;
      this.cameraFBO.lookAt(new THREE.Vector3(0, 0, 0));

      let geo = new THREE.PlaneGeometry(2, 2, 2, 2);
      this.geo = new THREE.BufferGeometry();
      let pos = new Float32Array(this.number * 3);
      let uv = new Float32Array(this.number * 2);
      for (let i = 0; i < this.size; i++) {
        for (let j = 0; j < this.size; j++) {
          const index = i * this.size + j;

          pos[3 * index] = this.size * lerp(-0.5, 0.5, j / (this.size - 1));
          pos[3 * index + 1] = this.size * lerp(-0.5, 0.5, i / (this.size - 1));
          pos[3 * index + 2] = 0;

          uv[2 * index] = j / (this.size - 1);
          uv[2 * index + 1] = i / (this.size - 1);
        }
      }
      this.geo.setAttribute("position", new THREE.BufferAttribute(pos, 3));
      this.geo.setAttribute("uv", new THREE.BufferAttribute(uv, 2));

      // this.geo.setDrawRange(3, 10);

      this.simMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          uMouse: { value: new THREE.Vector3(0, 0, 0) },
          uProgress: { value: 0 },
          uTime: { value: 0 },
          uSource: { value: new THREE.Vector3(0, 0, 0) },
          uRenderMode: { value: 0 },
          uCurrentPosition: { value: this.data1 },
          uDirections: { value: null },
        },
        vertexShader: simVertex,
        fragmentShader: simFragment,
        side: THREE.DoubleSide,
      });
      this.simMesh = new THREE.Points(this.geo, this.simMaterial);
      this.simMesh.frustumCulled = false;
      this.sceneFBO.add(this.simMesh);

      this.renderTarget = new THREE.WebGLRenderTarget(this.size, this.size, {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType,
      });
      this.directions = new THREE.WebGLRenderTarget(this.size, this.size, {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType,
      });

      this.initPos = new THREE.WebGLRenderTarget(this.size, this.size, {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType,
      });

      this.renderTarget1 = new THREE.WebGLRenderTarget(this.size, this.size, {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType,
      });
    }

    resize() {
      this.width = this.container.offsetWidth;
      this.height = this.container.offsetHeight;

      this.renderer.setSize(this.width, this.height);
      this.camera.aspect = this.width / this.height;

      this.camera.updateProjectionMatrix();
    }

    addObjects() {
      this.geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(this.number * 3);
      const uvs = new Float32Array(this.number * 2);
      for (let i = 0; i < this.size; i++) {
        for (let j = 0; j < this.size; j++) {
          const index = i * this.size + j;

          positions[3 * index] = j / this.size - 0.5;
          positions[3 * index + 1] = i / this.size - 0.5;
          positions[3 * index + 2] = 0;
          uvs[2 * index] = j / (this.size - 1);
          uvs[2 * index + 1] = i / (this.size - 1);
        }
      }
      this.geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      this.geometry.setAttribute("uv", new THREE.BufferAttribute(uvs, 2));

      this.material = new THREE.MeshNormalMaterial();

      this.material = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          // uTexture: { value: new THREE.TextureLoader().load(texture) },
          uTexture: { value: this.positions },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        depthWrite: false,
        depthTest: false,
        transparent: true,
        side: THREE.DoubleSide,
      });

      this.mesh = new THREE.Points(this.geometry, this.material);
      this.mesh.frustumCulled = false;
      this.scene.add(this.mesh);
      this.mesh.visible = false;
      refMesh.current = this.mesh;

      this.debugPlane = new THREE.Mesh(
        new THREE.PlaneGeometry(1, 1, 1, 1),
        new THREE.MeshBasicMaterial({
          //   map: new THREE.TextureLoader().load(t1),
        })
      );
      // this.scene.add(this.debugPlane);

      this.emitter = new THREE.Mesh(
        new THREE.BoxGeometry(0.05, 0.05, 0.05),
        new THREE.MeshBasicMaterial({
          color: 0xff0000,
        })
      );
      // this.scene.add(this.emitter);

      this.emitterDir = new THREE.Vector3(0, 0, 0);
      this.emitterPrev = new THREE.Vector3(0, 0, 0);
    }

    moveEmitter() {
      this.emitter.position.x = Math.sin(this.time) * 0.5;
    }

    // render() {
    //   if (this.group) {
    //     this.group.rotation.z += 0.06;
    //   }
    //   // this.time += 0.05;
    //   this.time += 0.001;
    //   this.moveEmitter();

    //   if (!this.init) {
    //     this.init = true;

    //     // // DIRECTIONS
    //     this.simMaterial.uniforms.uRenderMode.value = 1;
    //     this.simMaterial.uniforms.uTime.value = -300;
    //     this.simMaterial.uniforms.uSource.value = new THREE.Vector3(0, 0, 0);
    //     this.renderer.setRenderTarget(this.directions);
    //     this.renderer.render(this.sceneFBO, this.cameraFBO);
    //     this.simMaterial.uniforms.uDirections.value = this.directions.texture;

    //     // // POSITIONS
    //     this.simMaterial.uniforms.uRenderMode.value = 2;
    //     this.simMaterial.uniforms.uSource.value = new THREE.Vector3(0, 0, 0);
    //     this.renderer.setRenderTarget(this.initPos);
    //     this.renderer.render(this.sceneFBO, this.cameraFBO);
    //     this.simMaterial.uniforms.uCurrentPosition.value = this.initPos.texture;
    //   }

    //   this.material.uniforms.time.value = this.time;

    //   // SIMULATION
    //   this.simMaterial.uniforms.uDirections.value = this.directions.texture;
    //   this.simMaterial.uniforms.uRenderMode.value = 0;
    //   this.geo.setDrawRange(0, this.number);
    //   this.renderer.setRenderTarget(this.renderTarget);
    //   this.renderer.render(this.sceneFBO, this.cameraFBO);

    //   // BEGIN EMITTER
    //   // let emit = 15;
    //   let emit = 10;
    //   this.renderer.autoClear = false;

    //   this.emitters.forEach((emitter) => {
    //     emitter.mesh.getWorldPosition(this.v);

    //     emitter.dir = new THREE.Vector3(0, -1, 0);

    //     const test = new THREE.Vector3(0, 0, 0);
    //     this.geo.setDrawRange(this.currentParticles, emit);

    //     // DIRECTIONS
    //     this.simMaterial.uniforms.uRenderMode.value = 1;
    //     this.simMaterial.uniforms.uDirections.value = null;
    //     this.simMaterial.uniforms.uCurrentPosition.value = null;
    //     // this.simMaterial.uniforms.uSource.value = emitter.dir;
    //     // this.simMaterial.uniforms.uSource.value = test;
    //     this.renderer.setRenderTarget(this.directions);
    //     this.renderer.render(this.sceneFBO, this.cameraFBO);

    //     // POSITIONS
    //     this.simMaterial.uniforms.uRenderMode.value = 2;
    //     this.simMaterial.uniforms.uSource.value = this.v;
    //     this.renderer.setRenderTarget(this.renderTarget);
    //     this.renderer.render(this.sceneFBO, this.cameraFBO);

    //     this.currentParticles += emit;
    //     if (this.currentParticles > this.number) {
    //       this.currentParticles = 0;
    //     }

    //     emitter.prev = this.v.clone();
    //   });
    //   // Esta linea comente para que ande el portal tambien
    //   // this.renderer.autoClear = true;

    //   // END OF EMIITER

    //   // RENDER SCENE

    //   this.renderer.setRenderTarget(null);
    //   // this.renderer.render(this.scene, this.camera);

    //   // swap render targets
    //   const tmp = this.renderTarget;
    //   this.renderTarget = this.renderTarget1;
    //   this.renderTarget1 = tmp;

    //   this.material.uniforms.uTexture.value = this.renderTarget.texture;
    //   this.simMaterial.uniforms.uCurrentPosition.value =
    //     this.renderTarget1.texture;
    //   this.simMaterial.uniforms.uTime.value = this.time;

    //   this.debugPlane.material.map = this.renderTarget.texture;

    //   window.requestAnimationFrame(this.render.bind(this));

    //   if (this.mixer) this.mixer.update(0.01);
    // }
    render() {
      if (this.group) {
        this.group.rotation.z += 0.06;
      }

      this.time += 0.001;
      this.moveEmitter();

      if (!this.init) {
        this.init = true;

        // DIRECTIONS
        this.simMaterial.uniforms.uRenderMode.value = 1;
        this.simMaterial.uniforms.uTime.value = -300;
        this.simMaterial.uniforms.uSource.value = new THREE.Vector3(0, 0, 0);
        this.renderer.setRenderTarget(this.directions);
        this.renderer.render(this.sceneFBO, this.cameraFBO);
        this.simMaterial.uniforms.uDirections.value = this.directions.texture;

        // POSITIONS
        this.simMaterial.uniforms.uRenderMode.value = 2;
        this.simMaterial.uniforms.uSource.value = new THREE.Vector3(0, 0, 0);
        this.renderer.setRenderTarget(this.initPos);
        this.renderer.render(this.sceneFBO, this.cameraFBO);
        this.simMaterial.uniforms.uCurrentPosition.value = this.initPos.texture;
      }

      this.material.uniforms.time.value = this.time;

      // SIMULATION
      this.simMaterial.uniforms.uDirections.value = this.directions.texture;
      this.simMaterial.uniforms.uRenderMode.value = 0;
      this.geo.setDrawRange(0, this.number);
      this.renderer.setRenderTarget(this.renderTarget);
      this.renderer.render(this.sceneFBO, this.cameraFBO);

      // BEGIN EMITTER
      let emit = 10;
      this.renderer.autoClear = false;

      // this.emitters.forEach((emitter) => {
      //   emitter.mesh.getWorldPosition(this.v);
      //   //Invert this.v

      //   // Calcula la dirección hacia el centro de `this.group`
      //   const center = new THREE.Vector3();
      //   this.group.getWorldPosition(center);

      //   emitter.dir = center.sub(this.v).normalize();

      //   // Invertir la dirección para que las partículas se dirijan hacia adentro
      //   // emitter.dir.negate();

      //   this.geo.setDrawRange(this.currentParticles, emit);

      //   // DIRECTIONS
      //   this.simMaterial.uniforms.uRenderMode.value = 1;
      //   this.simMaterial.uniforms.uDirections.value = null;
      //   this.simMaterial.uniforms.uCurrentPosition.value = null;
      //   this.simMaterial.uniforms.uSource.value = emitter.dir;
      //   this.renderer.setRenderTarget(this.directions);
      //   this.renderer.render(this.sceneFBO, this.cameraFBO);

      //   // POSITIONS
      //   this.simMaterial.uniforms.uRenderMode.value = 2;
      //   this.simMaterial.uniforms.uSource.value = this.v;
      //   this.renderer.setRenderTarget(this.renderTarget);
      //   this.renderer.render(this.sceneFBO, this.cameraFBO);

      //   this.currentParticles += emit;
      //   if (this.currentParticles > this.number) {
      //     this.currentParticles = 0;
      //   }

      //   emitter.prev = this.v.clone();
      // });
      this.emitters.forEach((emitter) => {
        emitter.mesh.getWorldPosition(this.v);

        // Calcula la dirección hacia el centro de `this.group`
        const center = new THREE.Vector3();
        this.group.getWorldPosition(center);

        // this.v.add(center).divideScalar(2);
        emitter.dir = center.sub(this.v).normalize();

        emitter.dir.x -= 1;
        emitter.dir.y += 0.5;

        // Aplicar una corrección manual para ajustar la dirección
        // const correction = new THREE.Vector3(-3, 0, -2); // Ajusta el valor de la corrección según sea necesario
        // emitter.dir.add(correction).normalize();

        this.geo.setDrawRange(this.currentParticles, emit);

        // DIRECTIONS
        this.simMaterial.uniforms.uRenderMode.value = 1;
        this.simMaterial.uniforms.uDirections.value = null;
        this.simMaterial.uniforms.uCurrentPosition.value = null;
        this.simMaterial.uniforms.uSource.value = emitter.dir;
        this.renderer.setRenderTarget(this.directions);
        this.renderer.render(this.sceneFBO, this.cameraFBO);

        // POSITIONS
        this.simMaterial.uniforms.uRenderMode.value = 2;
        this.simMaterial.uniforms.uSource.value = this.v;
        this.renderer.setRenderTarget(this.renderTarget);
        this.renderer.render(this.sceneFBO, this.cameraFBO);

        this.currentParticles += emit;
        if (this.currentParticles > this.number) {
          this.currentParticles = 0;
        }

        emitter.prev = this.v.clone();
      });

      // Esta linea comente para que ande el portal tambien
      // this.renderer.autoClear = true;

      // END OF EMITTER

      // RENDER SCENE

      this.renderer.setRenderTarget(null);

      // swap render targets
      const tmp = this.renderTarget;
      this.renderTarget = this.renderTarget1;
      this.renderTarget1 = tmp;

      this.material.uniforms.uTexture.value = this.renderTarget.texture;
      this.simMaterial.uniforms.uCurrentPosition.value =
        this.renderTarget1.texture;
      this.simMaterial.uniforms.uTime.value = this.time;

      this.debugPlane.material.map = this.renderTarget.texture;

      window.requestAnimationFrame(this.render.bind(this));

      if (this.mixer) this.mixer.update(0.01);
    }
  }

  useEffect(() => {
    new Sketch();
  }, []);

  useEffect(() => {
    return;
    console.log("Ring, refGroup", refGroup.current);

    if (!refGroup.current) return;
    refGroup.current.scale.set(scale, scale, scale);
    if (scale > 0) {
      refMesh.current.visible = true;
      refMeshBox.current.visible = true;
      refMeshBox2.current.visible = true;
      refMeshBox3.current.visible = true;
      refMeshBox4.current.visible = true;
    }
  }, [scale]);
  useEffect(() => {
    if (!refGroup.current) return;
    gsap.to(refGroup.current.scale, {
      x: 17,
      y: 17,
      z: 17,
      duration: animationTimePortal,
      ease: "power4.inOut",
      onUpdate: () => {
        // if (refGroup.current.scale.x > 13) {
        //   refMesh.current.visible = true;
        //   refMeshBox.current.visible = true;
        //   refMeshBox2.current.visible = true;
        //   refMeshBox3.current.visible = true;
        //   refMeshBox4.current.visible = true;
        // }
      },
    });
    refMesh.current.visible = true;
    refMeshBox.current.visible = true;
    refMeshBox2.current.visible = true;
    refMeshBox3.current.visible = true;
    refMeshBox4.current.visible = true;
  }, [startAnimation]);
  return (
    <group ref={refGroupPrincipal}>
      {/* <mesh>
        <boxGeometry args={[10, 10, 10]} />
        <meshBasicMaterial color="green" />
      </mesh>{" "} */}
    </group>
  );
};

export default Ring;
// new Sketch({
//   dom: document.getElementById("container"),
// });
