import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";

import { useControls } from "leva";
import { Perf } from "r3f-perf";
import CameraAnimations from "./animations/CameraAnimations";
import ScenesCityAndPortal from "./layout/ScensCityAndPortal/ScenesCityAndPortal";
import SceneJet from "./layout/SceneJet/SceneJet";

const Experience = () => {
  const [scaleRing, setScaleRing] = useState(1);

  const refCamera = useRef();
  const controls = useControls("General", {
    envLight: { value: 0.51, min: 0, max: 1 },
    ambLight: { value: 1, min: 0, max: 2 },
    luminanceThreshold: {
      value: 0,
      min: 0.0,
      max: 3.0,
      label: "Luminance Threshold",
    },
    mipmapBlur: { value: true, label: "Mipmap Blur" },
    radius: { value: 0.1, min: 0.0, max: 1, label: "Radius" },
    intensity: { value: -1, min: 0.0, max: 3.0, label: "Intensity" },
    x: { value: 9, min: -10, max: 20 },
    y: { value: 14.7, min: 0, max: 30 },
    z: { value: 19.5, min: -10, max: 40 },
    dirIntensity: { value: 0, min: 0, max: 10 },
    colorLight: "#696969",
    // colorShadow: "#693b3b",
    // #583333
    // #5f3838
    colorShadow: "#583333",
    //
    opacityShadow: { value: 0.44, min: 0, max: 1 },
  });

  return (
    <Canvas gl={{ logarithmicDepthBuffer: true }}>
      <color attach="background" args={["#CCC"]} />
      {/* (-30, 1500, -43); */}
      {/* <OrbitControls target={[-30, 1500, -43]} /> */}
      <CameraAnimations />
      <ambientLight intensity={controls.ambLight} />
      <Perf position="top-left" deepAnalyze={false} overClock />
      <ScenesCityAndPortal controls={controls} />
      {/* <SceneJet /> */}
    </Canvas>
  );
};

export default Experience;

// useFrame((state, delta) => {
//   const action = actions['Take 001']
//   // The offset is between 0 and 1, you can apply it to your models any way you like
//   const offset = 1 - scroll.offset
//   action.time = THREE.MathUtils.damp(action.time, (action.getClip().duration / 2) * offset, 100, delta)
//   state.camera.position.set(Math.sin(offset) * -10, Math.atan(offset * Math.PI * 2) * 5, Math.cos((offset * Math.PI) / 3) * -10)
//   state.camera.lookAt(0, 0, 0)
// })
