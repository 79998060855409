/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 modern_block3.glb --transform --instanceall
*/

import React, { useRef, useMemo, useContext, createContext } from 'react'
import { useGLTF, Merged } from '@react-three/drei'

const context = createContext()
export function Instances({ children,   ...props }) {
  const { nodes } = useGLTF('/models/modern_block3-transformed.glb')
  const instances = useMemo(
    () => ({
      Borders: nodes.borders_6_0,
      Bridge: nodes.bridge_5_0,
      Bridge1: nodes.bridge_6_0,
      Cube: nodes.Cube_1_0,
      Cube1: nodes.Cube_2_0,
      Cube2: nodes.Cube_3_0,
      Cube3: nodes.Cube_4_0,
      Cube4: nodes.Cube002_1_0,
      Cube5: nodes.Cube002_2_0,
      Cube6: nodes.Cube002_3_0,
      Cube7: nodes.Cube002_7_0,
      Cube8: nodes.Cube009_1_0,
      Cube9: nodes.Cube009_2_0,
      Cube10: nodes.Cube009_3_0,
      Cube11: nodes.Cube009_4_0,
      Cube12: nodes.Cube192_1_0,
      Cube13: nodes.Cube192_2_0,
      Cube14: nodes.Cube192_3_0,
      Cube15: nodes.Cube192_7_0,
      Fences: nodes.fences_3_0,
      Fences1: nodes.fences_6_0,
      Markup: nodes.markup_6_0,
      Pillars: nodes.pillars_6_0,
      Plane: nodes.Plane001_1_0_1,
      Plane1: nodes.Plane001_1_0_2,
      Plane2: nodes.Plane001_1_0_3,
      Plane3: nodes.Plane036_1_0,
      Plane4: nodes.Plane036_2_0,
      Plane5: nodes.Plane036_3_0,
      Plane6: nodes.Plane048_1_0,
      Plane7: nodes.Plane048_2_0,
      Plane8: nodes.Plane048_3_0,
      Plane9: nodes.Plane056_1_0,
      Plane10: nodes.Plane056_2_0,
      Plane11: nodes.Plane056_3_0,
      Plane12: nodes.Plane056_4_0,
      Plane13: nodes.Plane057_1_0,
      Plane14: nodes.Plane057_2_0,
      Plane15: nodes.Plane057_3_0,
      Plane16: nodes.Plane057_4_0,
      Roads: nodes.roads_5_0,
      Terrain: nodes.terrain_4_0,
      Terrain1: nodes.terrain_5_0,
      Terrain2: nodes.terrain_6_0,
    }),
    [nodes]
  )
  return (
    <Merged meshes={instances} {...props}>
      {(instances) => <context.Provider value={instances} children={children} />}
    </Merged>
  )
}

export function ModernBlock3(props) {
  const instances = useContext(context)
  return (
    <group {...props} dispose={null} position={[
      props.controlsModern.positionX,
      props.controlsModern.positionY,
      props.controlsModern.positionZ,
    ]}
    rotation={[0, props.controlsModern.rotationY, 0]}>
      <group position={[0, 75.97, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={0.05}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          {/* <group position={[-4601.38, -62.44, 13783.84]} rotation={[-Math.PI / 2, 0, -0.81]} scale={100}>
            <instances.Borders position={[12.2, -27.39, -0.22]} />
          </group> */}
          <group position={[-12130.83, -59.31, 9716.06]} rotation={[-Math.PI / 2, 0, 0.37]} scale={100}>
            {/* <instances.Bridge position={[1.77, 1.94, 0.59]} />
            <instances.Bridge1 position={[1.87, 0.51, -0.4]} /> */}
          </group>
          <group position={[8233.97, 4622.25, 10918.82]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <instances.Cube position={[-0.05, 32.66, -2.28]} />
            <instances.Cube1 position={[-0.04, -3.57, 30.57]} />
            <instances.Cube2 position={[-0.04, -5.27, 43.64]} />
            <instances.Cube3 position={[0, -0.84, -2.76]} />
          </group>
          <group position={[-21598.1, 4956.98, 12792.47]} rotation={[-Math.PI / 2, 0, 0.75]} scale={100}>
            <instances.Cube4 position={[3.84, 4.47, -10.18]} />
            <instances.Cube5 position={[3.52, 6.51, 5.31]} />
            <instances.Cube6 position={[1.93, 5.57, 23.41]} />
            <instances.Cube7 position={[0.85, 6.31, -3.92]} />
          </group>
          <group position={[-20992.2, 4048.2, 24447.97]} rotation={[-Math.PI / 2, 0, 0.74]} scale={100}>
            <instances.Cube8 position={[0, 38.35, -0.36]} />
            <instances.Cube9 position={[-0.05, -3.23, 28.24]} />
            <instances.Cube10 position={[-0.03, -5.5, 37.9]} />
            <instances.Cube11 position={[319.97, 38.18, -55.2]} />
          </group>
          <group position={[4283.74, 5551.26, 2292.18]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <instances.Cube12 position={[-0.27, -0.31, -15.34]} />
            <instances.Cube13 position={[-0.39, 5.6, 14.83]} />
            <instances.Cube14 position={[2.03, 3.16, 25.1]} />
            <instances.Cube15 position={[-0.73, 4.5, -3]} />
          </group>
          <group position={[-8452.37, -18.81, 12080.65]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <instances.Fences position={[-42.49, 8.69, 0.53]} />
            <instances.Fences1 position={[-45.6, 12.23, 0.68]} />
          </group>
          <group position={[-4560.74, -78.14, 13815.17]} rotation={[-Math.PI / 2, 0, 0.75]} scale={424.45}>
            <instances.Markup position={[-7.06, -5.85, -0.08]} />
          </group>
          <group position={[-12534.58, -343.84, 9555.83]} rotation={[-Math.PI / 2, 0, 0.37]} scale={100}>
            <instances.Pillars position={[-3.7, -6.42, 2.03]} />
          </group>
          <group position={[-13726.88, 3467.31, 18715.26]} rotation={[-Math.PI / 2, 0, 0.75]} scale={100}>
            <group position={[-2.82, 1.68, -8.67]}>
              <instances.Plane />
              <instances.Plane1 />
              <instances.Plane2 />
            </group>
          </group>
          <group position={[-3460.28, 3468.02, 11181.68]} rotation={[-Math.PI / 2, 0, 0.71]} scale={100}>
            <instances.Plane3 position={[-9.05, -8.15, -9.28]} />
            <instances.Plane4 position={[-8.1, -12.52, -5.68]} />
            <instances.Plane5 position={[-6.08, -7.84, -5.83]} />
          </group>
          <group position={[-8124.78, 3650.49, 2779.92]} rotation={[-Math.PI / 2, 0, 0.74]} scale={100}>
            <instances.Plane6 position={[-12.44, 14.03, -11.49]} />
            <instances.Plane7 position={[-0.12, 15.51, -3.63]} />
            <instances.Plane8 position={[-4.21, 15.17, -8.18]} />
          </group>
          <group position={[2340.77, 3607.79, 22216.28]} rotation={[-Math.PI / 2, 0, 0.74]} scale={100}>
            <instances.Plane9 position={[-0.92, 14.16, 15.62]} />
            <instances.Plane10 position={[1.63, 14.94, 8.61]} />
            <instances.Plane11 position={[5.09, 14.48, -6.36]} />
            <instances.Plane12 position={[-1.28, 15.86, -9.96]} />
          </group>
          <group position={[-9364.87, 4071.9, 32018.56]} rotation={[-Math.PI / 2, 0, -2.43]} scale={100}>
            <instances.Plane13 position={[-12.12, -12.13, 18.08]} />
            <instances.Plane14 position={[-10.39, -11.83, 2.24]} />
            <instances.Plane15 position={[-12.08, -7.79, -7.72]} />
            <instances.Plane16 position={[-6.54, -9.66, -9.98]} />
          </group>
          <group position={[-4560.93, -78.56, 13815.26]} rotation={[-Math.PI / 2, 0, 0.75]} scale={424.45}>
            <instances.Roads position={[-5.59, -3.96, -0.07]} />
          </group>
          <group position={[-9983.08, -230.72, 15842.27]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <instances.Terrain position={[-27.05, 55.52, -0.46]} />
            <instances.Terrain1 position={[18.98, 12.35, 1.51]} />
            <instances.Terrain2 position={[-27.56, 53.98, 1.07]} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/modern_block3-transformed.glb')
