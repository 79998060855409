import * as THREE from "three";
import { HDRJPGLoader } from "@monogrid/gainmap-js";
import { useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useControls } from "leva";
const EnvironmentCity = ({ refSceneCity }) => {
  const { gl } = useThree();
  const refMesh = useRef();

  const controlsEnviromentCity = useControls("City", {
    rotationY: { value: 0.25, min: -Math.PI, max: Math.PI },
  });
  useEffect(() => {
    (async () => {
      // gl.toneMapping = THREE.ACESFilmicToneMapping;
      // gl.toneMappingExposure = 1.0;
      const loader = new HDRJPGLoader(gl);

      // const result = await loader.loadAsync(
      //   "/enviroments2/M3_Photoreal_equirectangular-jpg_ocean_with_only_little_1292363544_11841915.jpg"
      // );

      const textureLoader = new THREE.TextureLoader();
      const texture = await textureLoader.loadAsync(
        "/enviroments2/M3_Photoreal_equirectangular-jpg_ocean_with_only_little_1292363544_11841915.jpg"
      );

      refMesh.current = new THREE.Mesh(
        new THREE.SphereGeometry(45, 40, 40),
        new THREE.MeshBasicMaterial({
          // map: result.renderTarget.texture,
          map: texture,
          color: "#d5d5ff",
          side: THREE.BackSide,
          toneMapped: true,
          // envMapRotation: Math.PI * 0.5,
        })
      );
      refMesh.current.scale.set(100, 70, 100);
      // refMesh.current.position.y = -37;
      refMesh.current.position.y = 290;
      refMesh.current.rotation.y = -1.85;
      // userData={{ lensflare: "no-occlusion" }}

      // mesh.userData = { lensflare: "no-occlusion" };
      // mesh.userData = "no-occlusion";
      refSceneCity.current.add(refMesh.current);
    })();
  }, []);
  // useFrame(() => {
  // if (refMesh.current) {
  //   refMesh.current.rotation.y += 0.001;
  // }
  // });

  useEffect(() => {
    if (!refMesh.current) return;
    refMesh.current.rotation.y = controlsEnviromentCity.rotationY;
  }, [controlsEnviromentCity.rotationY]);
  return null;
};

export default EnvironmentCity;
