import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import gsap from "gsap";

const CameraAnimations = () => {
  //   const { camera } = useThree();
  const get = useThree((state) => state.get);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "c") {
        const camera = get().camera;
        gsap.to(camera.position, {
          z: 20,
          x: 20,
          duration: 9,
          onComplete: () => {
            // gsap.to(camera.position, {
            //   z: 20,
            //   x: 20,
            // });
            // console.log("posicion camara", this.camera.position);
          },
          ease: "power4.inOut",
        });
      }
    });
  }, []);
  return null;
};

export default CameraAnimations;
