import { useFBX, useGLTF } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import {
  SUBTRACTION,
  Brush,
  Evaluator,
  INTERSECTION,
  DIFFERENCE,
  REVERSE_SUBTRACTION,
  ADDITION,
} from "three-bvh-csg";
const ModernBlock = ({ controlsModern }) => {
  const { scene, gl } = useThree();
  const glb = useGLTF("/models/modern_block3-transformed.glb");
  const refCloneFloor = useRef(null);
  const refGroup = useRef(null);
  useEffect(() => {
    // const localPlane = new THREE.Plane(new THREE.Vector3(20, 0, 0.01), 1);
    // const globalPlane = new THREE.Plane(new THREE.Vector3(-1, 0, 0), 0.1);
    // scene.add(localPlane);
    // console.log("glb es ", glb);

    glb.scene.traverse((child) => {
      if (child.isMesh) {
        // return;
        // child.layers.set(2);
        // child.renderOrder = 20;
        child.material = new THREE.MeshBasicMaterial({
          map: child.material.map,
          // color: "red",
          // clippingPlanes: [localPlane],
          // clipShadows: true,
          // stencilWrite: true,
          // stencilRef: 0,
          // alphaToCoverage: true,
        });
        // child.material = new THREE.MeshStandardMaterial({
        //   map: child.material.map,
        // });
        if (child.name.includes("Plane001_3_0")) {
          // console.log("child es ", child);
          child.material.color = new THREE.Color(0xff0000);
        }
      }
    });
    // ***** Clipping setup (renderer): *****

    // gl.localClippingEnabled = true;
  }, []);

  return (
    <group
      ref={refGroup}
      position={[
        controlsModern.positionX,
        controlsModern.positionY,
        controlsModern.positionZ,
      ]}
      rotation={[0, controlsModern.rotationY, 0]}
    >
      <primitive object={glb.scene} scale={1} />
    </group>
  );
};

export default ModernBlock;
