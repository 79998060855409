/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 jet3.glb --transform
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations, useScroll } from '@react-three/drei'
import { objectScrollManagment } from '../../../utils/ScrollManagment'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'

export function Jet3(props) {
  const scroll = useScroll();
  const refJet = useRef();

  const refGroupChairPilot = useRef();
  const { nodes, materials, animations } = useGLTF('/jet/jet3-transformed.glb')
  const { actions } = useAnimations(animations, refGroupChairPilot)
  const refParabrisas = useRef();
  const refChair = useRef();
  const refInitialPositionX = useRef(-100);
  const refInitialPositionY = useRef(0);
  const refInitialPositionParabrisasY = useRef(0);
  const refInitialPositionParabrisasX = useRef(0);
  const refInitialRotationParabrisasZ = useRef(0);
  const refInitialPositionChairY = useRef(0);
  const refInitialPositionChairX = useRef(0);

  useEffect(() => {
    refInitialRotationParabrisasZ.current = refParabrisas.current.rotation.z;
    const vector = new THREE.Vector3();
    refGroupChairPilot.current.getWorldPosition(vector);
    console.log("vector 1 es", vector);
    const positionOriginalChairPilot = new THREE.Vector3(-100,1480,0);
  }, []);
  useFrame((state, delta) => {
    if (!refJet.current) return;
    //    state.camera.position.set(146, 1510, -52);
    // state.camera.lookAt(-30, 1500, -43);
    const offset = scroll.offset;
    // state.camera.position.set(146, 1510, -52);
    // state.camera.lookAt(-30, 1500, -43);
    objectScrollManagment["1"].execute(({ normalizedOffset }) => {
      refJet.current.rotation.x = THREE.MathUtils.lerp(
        0,
        Math.PI * 0.2,
        normalizedOffset
      );
    }, offset);
    objectScrollManagment["2"].execute(({ normalizedOffset }) => {
      refJet.current.rotation.x = THREE.MathUtils.lerp(
        Math.PI * 0.2,
        -Math.PI * 0.2,
        normalizedOffset
      );
    }, offset);
    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        refJet.current.rotation.x = THREE.MathUtils.lerp(
          -Math.PI * 0.2,
          0,
          normalizedOffset
        );
      },
      offset,
      0.6
    );

    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        const targetPositionX = 5000;
        const targetPositionY = -2000;
        let scrollFactor = offset * normalizedOffset;

        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }
        // Control directo de la posición, sin `lerp`
        refJet.current.position.x =
          refInitialPositionX.current +
          scrollFactor * (targetPositionX - refInitialPositionX.current);
        refJet.current.position.y =
          refInitialPositionY.current +
          scrollFactor * (targetPositionY - refInitialPositionY.current);
      },
      offset,
      1,
      0.5
    );

    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        const targetPositionY = 200; // La posición objetivo en el eje Y
        const targetRotationZ = -60; // La rotación objetivo en el eje Z
        // if (normalizedOffset > 0.9) {
        //   normalizedOffset = 1;
        // }
        let scrollFactor = offset * normalizedOffset; // Cálculo del factor de scroll
        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }
        // refParabrisas.current.rotation.z = THREE.MathUtils.lerp(
        //   Math.PI * 0.0045,
        //   -Math.PI * 0.5,
        //   normalizedOffset
        // );
        refParabrisas.current.rotation.z =   refInitialRotationParabrisasZ.current +
        scrollFactor *
          (targetRotationZ - refInitialRotationParabrisasZ.current);
        // Control directo de la posición en Y, sin `lerp`
        refParabrisas.current.position.y =
          refInitialPositionParabrisasY.current +
          scrollFactor *
            (targetPositionY - refInitialPositionParabrisasY.current);
      },
      offset,
      0.5
    );

    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        let scrollFactor = offset * normalizedOffset; // Cálculo del factor de scroll
        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }

        // if (refParabrisas.current.position.y > 2) {
        const targetPositionX = 1000; // La posición objetivo en el eje Y
        refParabrisas.current.position.x =
          refInitialPositionParabrisasX.current +
          scrollFactor *
            (targetPositionX - refInitialPositionParabrisasX.current);
        // }
      },
      offset,
      0.9, //Este debe ser mayor al siguiente
      0.2
    );
    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        return;
        let scrollFactor = offset * normalizedOffset; // Cálculo del factor de scroll
        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }

        const targetPositionY = +130; // La posición objetivo en el eje Y
        refGroupChairPilot.current.position.x =
          refInitialPositionChairX.current;
        refGroupChairPilot.current.position.y =
          refInitialPositionChairY.current +
          scrollFactor * (targetPositionY - refInitialPositionChairY.current);
      },
      offset,
      0.9, //Este debe ser mayor al siguiente
      0.1
    );
  });
  useEffect(() => {
    console.log("actions", actions);
    actions["Armature|mixamo.com|Layer0"].play();
  }, []);
  return (
    <>
      {/* RefJet */}
      {/* <group position={[-100,0,0]} rotation={[0, 2.94, 0]} scale={10}> */}

      <group ref={refJet} {...props} dispose={null}  position={[-100,0,0]} rotation={[0, 2.94, 0]} scale={10}>
        <group name="Scene">
          <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
            <group name="root">
              <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
                <group name="Falcon_Jet_40">
                  <group name="Boosters_2">
                    <mesh name="Object_5" geometry={nodes.Object_5.geometry} material={materials.Boosters} />
                  </group>
                  <group name="Main_Body_6">
                    <mesh name="Object_10" geometry={nodes.Object_10.geometry} material={materials.Cockpit_Interior} position={[0, -0.24, 0]} />
                    <mesh name="Object_9" geometry={nodes.Object_9.geometry} material={materials.Falcon} position={[0, -0.24, 0]} />
                  </group>
                  <group name="parabrisas001" ref={refParabrisas}>
                    <mesh name="parabrisas" geometry={nodes.parabrisas.geometry} material={materials.Cockpit_Canopy} position={[0, -0.24, 0]} scale={[1, 1.02, 1]} />
                  </group>
                  <group name="Trail_39" position={[5.75, -0.47, 1.22]}>
                    <mesh name="Object_48" geometry={nodes.Object_48.geometry} material={materials.Booster_Trail} />
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
  
      {/* RefGroupChairPilot - Mover fuera de refJet */}
      <group ref={refGroupChairPilot} position={[-100,0,0]} rotation={[0, 2.94, 0]} scale={10}>
        <group name="Sketchfab_model002" position={[0, 4.93, 0]} rotation={[1.56, 0, 0]} scale={-41.76}>
          <group name="676befd860664be884907ea0088f2c02fbx001" rotation={[Math.PI / 2, 0, 0]} scale={10}>
            <group name="RootNode001">
              <group name="Seat001" position={[0, 6.08, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                <mesh name="Seat_seat_mat_0" geometry={nodes.Seat_seat_mat_0.geometry} material={materials['seat_mat.001']} position={[0.27, 0.02, -1.46]} rotation={[0, 0.16, 0]} scale={[0.23, 0.28, 0.3]} />
              </group>
            </group>
          </group>
        </group>
        <group name="Armature" position={[-1.37, -1.18, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh name="Soldier_body" geometry={nodes.Soldier_body.geometry} material={materials['Soldier_body1.001']} skeleton={nodes.Soldier_body.skeleton} />
          <group name="Soldier_head">
            <skinnedMesh name="Soldier_head_1" geometry={nodes.Soldier_head_1.geometry} material={materials['Soldier_body1.001']} skeleton={nodes.Soldier_head_1.skeleton} />
            <skinnedMesh name="Soldier_head_2" geometry={nodes.Soldier_head_2.geometry} material={materials.Soldier_head6} skeleton={nodes.Soldier_head_2.skeleton} />
          </group>
        </group>
        <mesh name="Cube" geometry={nodes.Cube.geometry} material={materials.Material} position={[-0.77, -0.22, -0.01]} scale={[0.04, 0.14, 0.12]} />
      </group>
      {/* </group> */}

    </>
  );
  
}

useGLTF.preload('/jet/jet3-transformed.glb')
