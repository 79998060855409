// import { Singleton } from "typescript-ioc";
import {
  Camera,
  Matrix4,
  NoToneMapping,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";
// import { PortalWorldObject } from "../object/portal-world-object";
// import { World } from "../scene/instance/world";
import * as THREE from "three";
import { RGBELoader } from "three/addons/loaders/RGBELoader.js";
import { GainMapLoader, HDRJPGLoader } from "@monogrid/gainmap-js";

import envImage from "./env_jpg_low_size.jpg?url=test";
// @Singleton
export class RendererComponent {
  // initSubject = new Subject();
  // init$ = this.initSubject.pipe();
  // initSubject;
  // init$;
  constructor(renderer, refSceneWestern, refSceneCity, receivePortalRoom) {
    this.scene1Sky = refSceneWestern.current;
    this.scene2Room = refSceneCity.current;
    this.objectPortal = null;
    this.renderer = renderer;
    this.stencilScene = new Scene();
    this.context = null;
    this.sceneGeneral = null;
    this.cube = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 1),
      new THREE.MeshStandardMaterial({ color: 0x00ff00 })
    );
    this.stencilMaterial = new THREE.MeshStandardMaterial({
      color: 0x00ff00,
      clippingPlanes: [],
      clipShadows: true,
    });
    this.receivePortalRoom = receivePortalRoom;

    this.cubeClone = this.cube.clone();
    this.groupCubes = new THREE.Group();
  }
  // renderer;
  // context;
  // sceneGeneral = null;
  init() {
    // if (canvas.transferControlToOffscreen) {
    //   // If you're using OffscreenCanvas, handle accordingly
    //   canvas = canvas.transferControlToOffscreen();
    // }
    // @ts-ignore
    // canvas["style"] = { width: canvas.width, height: canvas.height };
    // this.renderer = new WebGLRenderer({
    //   canvas,
    //   context: canvas.getContext("webgl2"),
    //   powerPreference: "high-performance",
    //   stencil: true,
    //   depth: true,
    //   alpha: true,
    //   antialias: true,
    // });
    // this.renderer.autoClear = false;
    // this.renderer.setPixelRatio(0.8);
    // this.renderer.shadowMap.enabled = false;
    // this.renderer.outputEncoding = LinearEncoding; //sRGBEncoding;
    // this.renderer.outputEncoding = THREE.LinearSRGBColorSpace; //sRGBEncoding;

    // this.renderer.toneMapping = NoToneMapping;
    // this.renderer.toneMappingExposure = 1;
    this.context = this.renderer.getContext();
    // this.controls = THREE.OrbitControls(this.camera, this.renderer.domElement);
    // this.initSubject.next();
    // const geometrybox = new THREE.BoxGeometry(1, 1, 1);
    // const materialbox = new THREE.MeshBasicMaterial({color: "red"});
    // this.cube = new THREE.Mesh(geometrybox, materialbox);
    // this.stencilScene.add(this.cube);
    this.test();
    this.eventListener = this.eventListener.bind(this);
    this.eventListener();
    this.camera = null;
  }

  eventListener() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "w") {
        this.camera.position.z -= 0.1;
      }
      if (e.key === "a") {
        this.camera.position.z += 0.1;
      }
      if (e.key === "r") {
        this.camera.rotation.y -= 0.1;
      }
      if (e.key === "t") {
        this.camera.rotation.y += 0.1;
      }
    });
  }
  test() {
    const geometrybox = new THREE.BoxGeometry(1, 1, 1);
    const materialbox = new THREE.MeshBasicMaterial({ color: "red" });
    const cube = new THREE.Mesh(geometrybox, materialbox);
    this.stencilScene.add(cube);
  }

  setSize(width, height) {
    this.renderer?.setSize(width, height);
  }
  async addCubes(allPortals) {
    // console.log("uno");
    (async () => {
      const loader = new HDRJPGLoader(this.renderer);

      // const result = await loader.loadAsync("/wasteland_clouds_puresky_4k.jpg");

      // this.sceneGeneral.environment = result.renderTarget.texture;
      // this.sceneGeneral.envIntensity = 1.0;
      // this.sceneGeneral.background = result.renderTarget.texture;
      // this.sceneGeneral.background.mapping =
      //   THREE.EquirectangularReflectionMapping;
      // this.sceneGeneral.background = "transparent";
    })();
    // this.sceneGeneral.background = new THREE.Color(0xff0000);
    this.cube.material = this.stencilMaterial;
    const array = Array.from(allPortals);
    // this.cube = new THREE.Mesh(geometrybox, stencilMaterial);
    this.cube.position.y = 0.9;
    this.cube.position.z = 3;
    this.cubeClone.position.y = 0.9;
    this.cubeClone.position.z = 3;

    // this.scene2Room
    // console.log("array", array);

    if (array[1].name.includes("SkyWorld")) {
      array[1].group = this.scene1Sky;
      // this.objectPortal = array[1].portal.mesh;
      console.log("array[1].group", array[1]);
    }
    if (array[0].name.includes("RoomWorld")) {
      array[0].group = this.scene2Room;
      this.receivePortalRoom(array[0].portal.mesh);
      // this.objectPortal = array[0].portal.mesh;
      console.log("Portal en renderer", this.objectPortal);
    }
    // array[1].group.add(this.cubeClone);
    // array[0].group.add(this.cube);

    const loader = new GainMapLoader(this.renderer);
    // const result = await loader.loadAsync("/symmetrical_garden_02_8k.jpg");

    // try {
    //   // const result = await loader.loadAsync(envImage);
    // } catch (error) {
    //   console.log("error es", error);
    // }
    // console.log("result", result);

    // loader.load("/env_jpg_low_size.jpg").then((result) => {
    //   console.log("result", result);
    // });

    // const hdrJpg = new HDRJPGLoader(this.renderer).load(
    //   "env_jpg_low_size.jpg",
    //   function (hdrJpg) {
    //     console.log("texture", hdrJpg);

    //     let skySphereGeometry = new THREE.SphereGeometry(500, 60, 60);
    //     // let skySphereMaterial = new THREE.MeshBasicMaterial({
    //     //   map: jpgTexture,
    //     // });
    //     // Crear un ShaderMaterial para controlar la intensidad y el tono de color
    //     const hdrJpgEquirectangularMap = hdrJpg.renderTarget.texture;
    //     const hdrJpgPMREMRenderTarget = pmremGenerator.fromEquirectangular(
    //       hdrJpgEquirectangularMap
    //     );

    //     hdrJpgEquirectangularMap.mapping =
    //       THREE.EquirectangularReflectionMapping;
    //     hdrJpgEquirectangularMap.needsUpdate = true;
    //     let skySphereMaterial = new THREE.ShaderMaterial({
    //       uniforms: {
    //         map: { value: hdrJpgEquirectangularMap },
    //         envIntensity: { value: 1.0 }, // Control de intensidad
    //         colorTone: { value: new THREE.Color(1, 1, 1) }, // Tono de color
    //       },
    //       vertexShader: `
    //           varying vec2 vUv;
    //           void main() {
    //             vUv = uv;
    //             gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    //           }
    //         `,
    //       fragmentShader: `
    //           uniform sampler2D map;
    //           uniform float envIntensity;
    //           uniform vec3 colorTone;
    //           varying vec2 vUv;
    //           void main() {
    //             vec4 texColor = texture2D(map, vUv);
    //             gl_FragColor = vec4(texColor.rgb * colorTone * envIntensity, texColor.a);
    //           }
    //         `,
    //       side: THREE.BackSide,
    //     });

    //     skySphereMaterial.side = THREE.BackSide;
    //     let skySphereMesh = new THREE.Mesh(
    //       skySphereGeometry,
    //       skySphereMaterial
    //     );

    //     array[1].group.add(skySphereMesh);
    //     // array[1].group.background = hdrJpgEquirectangularMap;
    //     // array[1].group.background = hdrJpgPMREMRenderTarget;

    //     // resolutions[ 'HDR JPG' ] = hdrJpg.width + 'x' + hdrJpg.height;
    //     // displayStats( 'HDR JPG' );

    //     // hdrJpgEquirectangularMap = hdrJpg.renderTarget.texture;
    //     // hdrJpgPMREMRenderTarget = pmremGenerator.fromEquirectangular( hdrJpgEquirectangularMap );

    //     // hdrJpgEquirectangularMap.mapping = THREE.EquirectangularReflectionMapping;
    //     // hdrJpgEquirectangularMap.needsUpdate = true;

    //     // hdrJpg.dispose();
    //   },
    //   function (progress) {
    //     // fileSizes[ 'HDR JPG' ] = humanFileSize( progress.total );
    //   }
    // );
    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    const hdriLoader = new RGBELoader();
    // hdriLoader.load("/env.hdr", function (texture) {
    let textureLoader = new THREE.TextureLoader();
    // const envMap = pmremGenerator.fromEquirectangular(texture).texture;
    // let texture = textureLoader.load(envImage);
    // textureLoader.load("/env_jpg_low_size.jpg", (jpgTexture) => {
    //   let skySphereGeometry = new THREE.SphereGeometry(500, 60, 60);
    //   // let skySphereMaterial = new THREE.MeshBasicMaterial({
    //   //   map: jpgTexture,
    //   // });
    //   // Crear un ShaderMaterial para controlar la intensidad y el tono de color
    //   let skySphereMaterial = new THREE.ShaderMaterial({
    //     uniforms: {
    //       map: { value: jpgTexture },
    //       envIntensity: { value: 1.2 }, // Control de intensidad
    //       colorTone: { value: new THREE.Color(1, 1, 1) }, // Tono de color
    //     },
    //     vertexShader: `
    //         varying vec2 vUv;
    //         void main() {
    //           vUv = uv;
    //           gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    //         }
    //       `,
    //     fragmentShader: `
    //         uniform sampler2D map;
    //         uniform float envIntensity;
    //         uniform vec3 colorTone;
    //         varying vec2 vUv;
    //         void main() {
    //           vec4 texColor = texture2D(map, vUv);
    //           gl_FragColor = vec4(texColor.rgb * colorTone * envIntensity, texColor.a);
    //         }
    //       `,
    //     side: THREE.BackSide,
    //   });
    //   skySphereMaterial.side = THREE.BackSide;
    //   let skySphereMesh = new THREE.Mesh(skySphereGeometry, skySphereMaterial);
    //   // array[1].group.add(skySphereMesh);
    // });
    // textureLoader.load("/symmetrical_garden_02_8k.jpg", (jpgTexture) => {
    //   // let skySphereGeometry = new THREE.SphereGeometry(50, 32, 32);
    //   let skySphereGeometry = new THREE.SphereGeometry(500, 60, 60);
    //   // let skySphereMaterial = new THREE.MeshBasicMaterial({
    //   //   map: jpgTexture,
    //   // });
    //   // Crear un ShaderMaterial para controlar la intensidad y el tono de color
    //   let skySphereMaterial = new THREE.ShaderMaterial({
    //     uniforms: {
    //       map: { value: jpgTexture },
    //       envIntensity: { value: 1.0 }, // Control de intensidad
    //       colorTone: { value: new THREE.Color(1, 1, 1) }, // Tono de color
    //     },
    //     vertexShader: `
    //         varying vec2 vUv;
    //         void main() {
    //           vUv = uv;
    //           gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    //         }
    //       `,
    //     fragmentShader: `
    //         uniform sampler2D map;
    //         uniform float envIntensity;
    //         uniform vec3 colorTone;
    //         varying vec2 vUv;
    //         void main() {
    //           vec4 texColor = texture2D(map, vUv);
    //           gl_FragColor = vec4(texColor.rgb * colorTone * envIntensity, texColor.a);
    //         }
    //       `,
    //     side: THREE.BackSide,
    //   });

    //   skySphereMaterial.side = THREE.BackSide;
    //   let skySphereMesh = new THREE.Mesh(skySphereGeometry, skySphereMaterial);

    //   array[0].group.add(skySphereMesh);
    // });
    // console.log("texture", texture);
    // const sphereGeometry = new THREE.SphereGeometry(2000, 2000, 2000);
    // const envMap = pmremGenerator.fromEquirectangular(texture).texture;
    // // texture.dispose();
    // // scene.environment = envMap
    // const sphereMaterial = new THREE.MeshBasicMaterial({
    //   envMap: envMap,
    //   side: THREE.BackSide,
    // });
    // console.log("envMap", envMap);
    // const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    // array[1].group.add(sphere);
    // });
    // array[1].group.background = result.renderTarget.texture;
    // array[1].group.background.mapping = THREE.EquirectangularReflectionMapping;
    // Crear la geometría del skybox
    // const geometry = new THREE.BoxGeometry(1000, 1000, 1000); // Ajusta el tamaño según necesites

    // var materials = [
    //   new THREE.MeshBasicMaterial({
    //     map: new THREE.TextureLoader().load("/env3/px.jpg"),
    //     side: THREE.BackSide,
    //   }),
    //   new THREE.MeshBasicMaterial({
    //     map: new THREE.TextureLoader().load("/env3/nx.jpg"),
    //     side: THREE.BackSide,
    //   }),
    //   new THREE.MeshBasicMaterial({
    //     map: new THREE.TextureLoader().load("/env3/py.jpg"),
    //     side: THREE.BackSide,
    //   }),
    //   new THREE.MeshBasicMaterial({
    //     map: new THREE.TextureLoader().load("/env3/ny.jpg"),
    //     side: THREE.BackSide,
    //   }),
    //   new THREE.MeshBasicMaterial({
    //     map: new THREE.TextureLoader().load("/env3/pz.jpg"),
    //     side: THREE.BackSide,
    //   }),
    //   new THREE.MeshBasicMaterial({
    //     map: new THREE.TextureLoader().load("/env3/nz.jpg"),
    //     side: THREE.BackSide,
    //   }),
    // ];

    // // Crear el skybox
    // const skybox = new THREE.Mesh(geometry, materials);

    // // Agregar el skybox al grupo de la escena deseada
    // array[1].group.add(skybox);
  }
  // const hdriLoader = new RGBELoader();

  // hdriLoader.load("/env.hdr", function (texture) {
  //   // const envMap = pmremGenerator.fromEquirectangular(texture).texture;
  //   console.log("texture", texture);

  //   // Convert the HDRI to a CubeTexture
  //   const cubeRenderTarget = pmremGenerator.fromEquirectangular(texture);
  //   const envMapCube = cubeRenderTarget.texture;

  //   // texture.dispose();
  //   // pmremGenerator.dispose();
  //   console.log("2 es", array[0].group);

  //   // Call the function to create the skybox
  //   // this.createSkybox(envMapCube, array[0].group);
  //   const geometry = new THREE.BoxGeometry(1000, 1000, 1000); // Ajusta el tamaño según necesites

  //   const material = new THREE.ShaderMaterial({
  //     uniforms: {
  //       envMap: { value: envMapCube },
  //     },
  //     vertexShader: `
  //     varying vec3 vWorldPosition;
  //     void main() {
  //       vec4 worldPosition = modelMatrix * vec4(position, 1.0);
  //       vWorldPosition = worldPosition.xyz;
  //       gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  //     }
  //   `,
  //     fragmentShader: `
  //     uniform samplerCube envMap;
  //     varying vec3 vWorldPosition;
  //     void main() {
  //       vec3 direction = normalize(vWorldPosition);
  //       gl_FragColor = textureCube(envMap, direction);
  //     }
  //   `,
  //     side: THREE.BackSide,
  //     // depthWrite: false,
  //   });
  //   const material2 = new THREE.MeshBasicMaterial({
  //     // envMap: envMapCube,
  //     side: THREE.BackSide,
  //     color: "red",
  //     // wireframe: true,
  //     map: envMapCube,
  //   });

  //   const skybox = new THREE.Mesh(geometry, material);
  //   array[1].group.add(skybox);
  //   // array[0].group.add(skybox);
  //   // array[1].group.background = envMapCube;
  // });
  // createSkybox(envMapCube, scene) {
  //   const geometry = new THREE.BoxGeometry(1000, 1000, 1000); // Ajusta el tamaño según necesites

  //   const material = new THREE.ShaderMaterial({
  //     uniforms: {
  //       envMap: { value: envMapCube },
  //     },
  //     vertexShader: `
  //       varying vec3 vWorldPosition;
  //       void main() {
  //         vec4 worldPosition = modelMatrix * vec4(position, 1.0);
  //         vWorldPosition = worldPosition.xyz;
  //         gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  //       }
  //     `,
  //     fragmentShader: `
  //       uniform samplerCube envMap;
  //       varying vec3 vWorldPosition;
  //       void main() {
  //         vec3 direction = normalize(vWorldPosition);
  //         gl_FragColor = textureCube(envMap, direction);
  //       }
  //     `,
  //     side: THREE.BackSide,
  //     depthWrite: false,
  //   });

  //   const skybox = new THREE.Mesh(geometry, material);
  //   scene.add(skybox);
  // }

  cameraMatrixWorld = new Matrix4();
  //  cameraProjectionMatrix = new Matrix4();
  cameraTmpPosition = new Vector3();

  async render(worlds, world, camera) {
    if (!this.renderer) {
      return;
    }
    this.camera = camera;
    this.setSize(window.innerWidth, window.innerHeight);
    const scene = world.getGroup();
    const portalsInScene = world.getPortals();

    // camera.position.z = 3;
    // camera.position.y = 1;

    // console.log("portalsInScene", portalsInScene);

    const gl = this.context;
    const geometrybox = new THREE.BoxGeometry(1, 1, 1);
    if (!this.sceneGeneral) {
      const allPortals = worlds.values();
      await this.addCubes(allPortals);
      console.log("prueba 1");
    }

    this.sceneGeneral = scene;
    // this.cube.position.z -= 0.01;
    // this.cubeClone.position.z -= 0.01;
    const roomPortal = portalsInScene[0].name.includes("RoomWorld.portal");
    let clipPlane;
    if (roomPortal) {
      clipPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);

      this.stencilMaterial.clippingPlanes = [clipPlane];
      this.stencilMaterial.clipShadows = true;
      this.renderer.localClippingEnabled = true;
    } else {
      this.cube.clippingPlanes = [];
    }

    // save camera matrices because they will be modified when rending a view through a portal
    // Activar estas siguientes dos lineas
    // camera.updateMatrixWorld();
    // this.cameraMatrixWorld.copy(camera.matrixWorld);

    // this.cameraProjectionMatrix.copy(camera.projectionMatrix);

    // full clear (color, depth and stencil)
    this.renderer.clear(true, true, true);
    // enable stencil test
    // gl.enable(gl.STENCIL_TEST);
    // disable stencil mask
    // gl.stencilMask(0xff);

    // TODO: Draw portals recursively

    portalsInScene
      //.sort((a, b) => Math.sign(a.getAbsolutePosition().distanceTo(camera.position) - b.getAbsolutePosition().distanceTo(camera.position)))
      .forEach((portal) => {
        // disable color + depth
        // only the stencil buffer will be drawn into
        gl.colorMask(false, false, false, false);
        gl.depthMask(false);

        // the stencil test will always fail (this is cheaper to compute)
        gl.stencilFunc(gl.NEVER, 1, 0xff);
        // fragments where the portal is drawn will have a stencil value of 1
        // other fragments will retain a stencil value of 0
        gl.stencilOp(gl.REPLACE, gl.KEEP, gl.KEEP);

        // render the portal shape using the settings above
        // set the portal as the only child of the stencil scene
        this.stencilScene.children = [portal.getGroup()];
        this.renderer.render(this.stencilScene, camera);

        // enable color + depth
        gl.colorMask(true, true, true, true);
        gl.depthMask(true);

        // fragments with a stencil value of 1 will be rendered
        gl.stencilFunc(gl.EQUAL, 1, 0xff);
        // stencil buffer is not changed
        gl.stencilOp(gl.KEEP, gl.KEEP, gl.KEEP);

        // compute the view through the portal
        camera.matrixAutoUpdate = false;
        camera.matrixWorldNeedsUpdate = false;
        const destinationScene = worlds
          .get(portal.getDestinationSceneName())
          .getGroup();
        this.cameraTmpPosition.copy(camera.position);
        const tmpNear = camera.near;
        camera.near = Math.max(
          tmpNear,
          camera.position.distanceTo(portal.getAbsolutePosition()) - 2
        );
        camera.updateProjectionMatrix();
        camera.matrixWorld.copy(this.computePortalViewMatrix(portal, camera));
        camera.position
          .copy(portal.getDestination().getAbsolutePosition())
          .sub(portal.getAbsolutePosition())
          .add(this.cameraTmpPosition);
        camera.updateMatrix();
        // camera.matrixWorldInverse.getInverse(camera.matrixWorld);
        camera.matrixWorldInverse.copy(camera.matrixWorld).invert();

        // camera.projectionMatrix.copy(this.computePortalProjectionMatrix(portal.getDestination(), camera));

        // destinationScene.add(this.cube);
        // this.stencilScene.add(this.cube);
        // render the view through the portal
        this.renderer.render(destinationScene, camera);

        // clear the stencil buffer for the next portal
        this.renderer.clear(false, false, true);

        // restore original camera matrices for the next portal
        camera.matrixAutoUpdate = true;
        camera.matrixWorldNeedsUpdate = true;
        camera.position.copy(this.cameraTmpPosition);
        camera.matrixWorld.copy(this.cameraMatrixWorld);
        camera.near = tmpNear;
        camera.updateProjectionMatrix();
        // camera.projectionMatrix.copy(this.cameraProjectionMatrix);
      });

    // after all portals have been drawn, we can disable the stencil test
    gl.disable(gl.STENCIL_TEST);

    // clear the depth buffer to remove the portal views' depth from the current scene
    this.renderer.clear(false, true, false);

    // all the current scene portals will be drawn this time
    this.stencilScene.children = portalsInScene.map((portal) =>
      portal.getGroup()
    );

    // disable color
    gl.colorMask(false, false, false, false);
    // draw the portal shapes into the depth buffer
    // this will make the portals appear as flat shapes
    this.renderer.render(this.stencilScene, camera);

    // enable color
    gl.colorMask(true, true, true, true);

    // finally, render the current scene
    this.renderer.render(scene, camera);
  }

  //  readonly rotationYMatrix = new Matrix4().makeRotationY(Math.PI);
  dstInverse = new Matrix4();
  srcToCam = new Matrix4();
  srcToDst = new Matrix4();
  result = new Matrix4();

  computePortalViewMatrix(sourcePortal, camera) {
    const destinationPortal = sourcePortal.getDestination();
    this.srcToCam.multiplyMatrices(
      camera.matrixWorldInverse,
      sourcePortal.getMatrix()
    );
    // this.dstInverse.getInverse(destinationPortal.getMatrix());
    this.dstInverse.copy(destinationPortal.getMatrix()).invert();

    this.srcToDst.identity().multiply(this.srcToCam).multiply(this.dstInverse);
    // this.srcToDst.identity().multiply(this.srcToCam).multiply(this.rotationYMatrix).multiply(this.dstInverse);
    // this.result.getInverse(this.srcToDst);
    this.result.copy(this.srcToDst).invert();

    return this.result;
  }

  /*
    readonly dstRotationMatrix = new Matrix4();
    readonly normal = new Vector3();
    readonly clipPlane = new Plane();
    readonly clipVector = new Vector4();
    readonly q = new Vector4();
    readonly projectionMatrix = new Matrix4();

    computePortalProjectionMatrix(destinationPortal: PortalWorldObject, camera: Camera): Matrix4 {
      // http://www.terathon.com/code/oblique.html
      this.dstRotationMatrix.identity();
      this.dstRotationMatrix.extractRotation(destinationPortal.getMatrix());

      this.normal.set(0, 0, 1).applyMatrix4(this.dstRotationMatrix);

      this.clipPlane.setFromNormalAndCoplanarPoint(this.normal, destinationPortal.getAbsolutePosition());
      this.clipPlane.applyMatrix4(camera.matrixWorldInverse);

      this.clipVector.set(this.clipPlane.normal.x, this.clipPlane.normal.y, this.clipPlane.normal.z, this.clipPlane.constant);

      this.projectionMatrix.copy(camera.projectionMatrix);

      this.q.x = (Math.sign(this.clipVector.x) + this.projectionMatrix.elements[8]) / this.projectionMatrix.elements[0];
      this.q.y = (Math.sign(this.clipVector.y) + this.projectionMatrix.elements[9]) / this.projectionMatrix.elements[5];
      this.q.z = -1.0;
      this.q.w = (1.0 + this.projectionMatrix.elements[10]) / camera.projectionMatrix.elements[14];

      this.clipVector.multiplyScalar(2 / this.clipVector.dot(this.q));

      this.projectionMatrix.elements[2] = this.clipVector.x;
      this.projectionMatrix.elements[6] = this.clipVector.y;
      this.projectionMatrix.elements[10] = this.clipVector.z + 1.0;
      this.projectionMatrix.elements[14] = this.clipVector.w;

      return this.projectionMatrix;
   }
   */
}
