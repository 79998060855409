import { Group, Matrix4, Object3D, Vector3 } from "three";
import { AbstractObject } from "./abstract-object";

export class WorldObject extends AbstractObject {
  group = new Group();
  physicalObjects = [];
  tmpVector = new Vector3();

  getMatrix() {
    return this.group.matrix;
  }

  getAbsolutePosition() {
    this.group.getWorldPosition(this.tmpVector);
    return this.tmpVector;
  }

  addPhysicalObject(object) {
    this.add(object);
    this.physicalObjects.push(object);
  }

  getPhysicalObjects() {
    return this.physicalObjects;
  }
}
