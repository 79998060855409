import { useFBX, useGLTF, useScroll } from "@react-three/drei";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import { HDRJPGLoader } from "@monogrid/gainmap-js";
import { useFrame, useThree } from "@react-three/fiber";
import { useControls } from "leva";
import {
  objectScrollManagment,
  TOTAL_ANIMATIONS,
} from "../../../utils/ScrollManagment";

const Jet = () => {
  const glb = useGLTF("/jet/jet3-transformed.glb");
  const parachuteChair = useGLTF("/parachutes/parachute_deployment_test.glb");
  const scroll = useScroll();
  const refGroup = useRef();
  const refMixer = useRef();
  const refMixerParachuteChair = useRef();
  /**
   *
   * Ref groups
   *
   */
  const refParabrisas = useRef();
  const refChair = useRef();
  const refGroupChairPilot = useRef(new THREE.Group());
  const refJet = useRef(new THREE.Group());

  /**
   *
   * Animations
   *
   */
  const refActionParachuteChair = useRef();

  const controlsJet = useControls("Jet", {
    rotationY: { value: 2.94, min: -Math.PI, max: Math.PI },
    // jetX: { value: -43, min: -100, max: 10 },
    jetX: { value: -100, min: -100, max: 10 },
  });
  const controlsParachuteChair = useControls("Parachute Chair", {
    rotationX: { value: 1.8, min: -Math.PI, max: Math.PI },
    rotationY: { value: 0.3, min: -Math.PI, max: Math.PI },
    rotationZ: { value: -1.6, min: -Math.PI, max: Math.PI },
  });
  const refInitialPositionX = useRef(controlsJet.jetX);
  const refInitialPositionY = useRef(0);
  const refInitialPositionParabrisasY = useRef(0);
  const refInitialPositionParabrisasX = useRef(0);
  const refInitialPositionChairY = useRef(0);
  const refInitialPositionChairX = useRef(0);

  useEffect(() => {
    console.log("Jet", glb);
    console.log("Parachute", parachuteChair);
    const animation = glb.animations[0];
    refMixer.current = new THREE.AnimationMixer(glb.scene);
    const action = refMixer.current.clipAction(animation);
    action.play();
    /**
     *
     * Aca busco los objetos
     *
     */
    refParabrisas.current = glb.scene.getObjectByName("parabrisas001");
    // refChair.current = glb.scene.getObjectByName("Sketchfab_model");
    const pilotBody = glb.scene.getObjectByName("Pilot_Body_37");

    const model001 = glb.scene.getObjectByName("Sketchfab_model001");
    const geo = glb.scene.getObjectByName("Geo");
    const model = glb.scene.getObjectByName("Sketchfab_model");
    refJet.current.add(model);
    // refJet.current.add(pilotBody);
    // refJet.current.add(model001);
    // refJet.current.add(geo);
    refJet.current.scale.set(10);
    refJet.current.scale.set(10, 10, 10);
    refJet.current.position.set(-100, 0, 0);
    refJet.current.rotation.y = 2.94;
    refGroup.current.add(refJet.current);
    //Silla
    const model002 = glb.scene.getObjectByName("Sketchfab_model002");
    //Piloto
    const armature = glb.scene.getObjectByName("Armature");
    // Cube
    const cube = glb.scene.getObjectByName("Cube");
    // refGroup.current = refChair.current;
    // refGroup.current = glb.scene;
    refGroupChairPilot.current.add(model002);
    refGroupChairPilot.current.add(armature);
    refGroupChairPilot.current.add(cube);
    refChair.current = cube;
    refInitialPositionChairY.current = refGroupChairPilot.current.position.y;
    refInitialPositionChairX.current = refGroupChairPilot.current.position.x;
    // glb.scene.add(refGroupChairPilot.current);
    refGroupChairPilot.current.scale.set(10, 10, 10);
    refGroupChairPilot.current.position.set(-100, 0, 0);
    refGroupChairPilot.current.rotation.y = 2.94;
    refGroup.current.add(refGroupChairPilot.current);

    glb.scene.traverse((child) => {
      if (child.isMesh) {
        if (child.name.includes("parabrisa")) {
          // child.position.y += 1;
          refParabrisas.current = child;

          refInitialPositionParabrisasY.current = child.position.y;
          refInitialPositionParabrisasX.current = child.position.x;
        }
        // if (child.name.includes("001")) {
        //   const material = new THREE.MeshStandardMaterial({ color: 0xff0000 });
        //   const geometry = child.geometry.clone();
        //   const mesh = new THREE.Mesh(geometry, material);
        //   mesh.position.copy(child.position);
        //   mesh.rotation.copy(child.rotation);
        //   mesh.scale.copy(child.scale);
        //   child.visible = false;
        //   glb.scene.add(mesh);
        // }
      }
    });
  }, []);

  useEffect(() => {
    /**
     *
     * Paracaidas de la silla
     *
     */
    parachuteChair.scene.traverse((child) => {
      if (child.isMesh) {
        child.material.color = new THREE.Color("#004000");
      }
    });
    const animation = parachuteChair.animations[0];
    refMixerParachuteChair.current = new THREE.AnimationMixer(
      parachuteChair.scene
    );
    refActionParachuteChair.current =
      refMixerParachuteChair.current.clipAction(animation);
    refActionParachuteChair.current.clampWhenFinished = true;
    refActionParachuteChair.current.setLoop(THREE.LoopOnce);
    // refActionParachuteChair.current.play();
    refActionParachuteChair.current.play();
    refActionParachuteChair.current.paused = true;
    parachuteChair.scene.rotation.x = -Math.PI * 0.25;
    parachuteChair.scene.rotation.y = Math.PI * 0.5;
    parachuteChair.scene.scale.set(0.2, 0.2, 0.2);
    parachuteChair.scene.position.x = -1.185;
    parachuteChair.scene.position.y = -0.36;
    refGroupChairPilot.current.add(parachuteChair.scene);
  }, []);

  useFrame((state, delta) => {
    if (refMixer.current) {
      refMixer.current.update(delta * 0.1);
    }
    if (refMixerParachuteChair.current) {
      refMixerParachuteChair.current.update(delta * 0.5);
    }
  });

  useFrame((state, delta) => {
    if (!refJet.current) return;
    const offset = scroll.offset;
    state.camera.position.set(146, 1510, -52);
    state.camera.lookAt(-30, 1500, -43);
    objectScrollManagment["1"].execute(({ normalizedOffset }) => {
      refJet.current.rotation.x = THREE.MathUtils.lerp(
        0,
        Math.PI * 0.2,
        normalizedOffset
      );
    }, offset);
    objectScrollManagment["2"].execute(({ normalizedOffset }) => {
      refJet.current.rotation.x = THREE.MathUtils.lerp(
        Math.PI * 0.2,
        -Math.PI * 0.2,
        normalizedOffset
      );
    }, offset);
    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        refJet.current.rotation.x = THREE.MathUtils.lerp(
          -Math.PI * 0.2,
          0,
          normalizedOffset
        );
      },
      offset,
      0.6
    );

    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        const targetPositionX = 5000;
        const targetPositionY = -2000;
        let scrollFactor = offset * normalizedOffset;

        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }
        // Control directo de la posición, sin `lerp`
        refJet.current.position.x =
          refInitialPositionX.current +
          scrollFactor * (targetPositionX - refInitialPositionX.current);
        refJet.current.position.y =
          refInitialPositionY.current +
          scrollFactor * (targetPositionY - refInitialPositionY.current);
      },
      offset,
      1,
      0.5
    );

    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        const targetPositionY = 200; // La posición objetivo en el eje Y
        // if (normalizedOffset > 0.9) {
        //   normalizedOffset = 1;
        // }
        let scrollFactor = offset * normalizedOffset; // Cálculo del factor de scroll
        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }
        // Control directo de la posición en Y, sin `lerp`
        refParabrisas.current.position.y =
          refInitialPositionParabrisasY.current +
          scrollFactor *
            (targetPositionY - refInitialPositionParabrisasY.current);
      },
      offset,
      0.3
    );

    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        let scrollFactor = offset * normalizedOffset; // Cálculo del factor de scroll
        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }

        // if (refParabrisas.current.position.y > 2) {
        const targetPositionX = 3000; // La posición objetivo en el eje Y
        refParabrisas.current.position.x =
          refInitialPositionParabrisasX.current +
          scrollFactor *
            (targetPositionX - refInitialPositionParabrisasX.current);
        // }
      },
      offset,
      0.6, //Este debe ser mayor al siguiente
      0.04
    );
    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        let scrollFactor = offset * normalizedOffset; // Cálculo del factor de scroll
        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }

        const targetPositionY = 700; // La posición objetivo en el eje Y

        refGroupChairPilot.current.position.y =
          refInitialPositionChairY.current +
          scrollFactor * (targetPositionY - refInitialPositionChairY.current);
      },
      offset,
      0.4, //Este debe ser mayor al siguiente
      0.1
    );
    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        let scrollFactor = offset * normalizedOffset; // Cálculo del factor de scroll
        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }
      },
      offset,
      0.9, //Este debe ser mayor al siguiente
      0.5
    );
    objectScrollManagment["3"].execute(
      ({ normalizedOffset }) => {
        let scrollFactor = offset * normalizedOffset; // Cálculo del factor de scroll
        if (scrollFactor < 0.003) {
          scrollFactor = 0;
        }
        // refActionParachuteChair.current.time = THREE.MathUtils.damp(
        //   refActionParachuteChair.current.time,
        //   (refActionParachuteChair.current.getClip().duration / 2) *
        //     normalizedOffset,
        //   100,
        //   delta
        // );
        refActionParachuteChair.current.time = THREE.MathUtils.damp(
          refActionParachuteChair.current.time,
          (refActionParachuteChair.current.getClip().duration / 2) *
            normalizedOffset, // Usamos solo normalizedOffset para controlar el tiempo de la animación
          100,
          delta
        );
        // refActionParachuteChair.current.play();
      },
      offset,
      0.9, //Este debe ser mayor al siguiente
      0.3
    );
    // action.time = THREE.MathUtils.damp(action.time, (action.getClip().duration / 2) * offset, 100, delta)
  });
  // useEffect(() => void (actions['Take 001'].play().paused = true), [actions])
  useEffect(() => {
    if (parachuteChair.scene) {
      parachuteChair.scene.rotation.x = controlsParachuteChair.rotationX;
      parachuteChair.scene.rotation.y = controlsParachuteChair.rotationY;
      parachuteChair.scene.rotation.z = controlsParachuteChair.rotationZ;
    }
  }, [
    controlsParachuteChair.rotationX,
    controlsParachuteChair.rotationY,
    controlsParachuteChair.rotationZ,
  ]);
  return (
    <>
      <group ref={refGroup}></group>
      {/* <primitive
        object={glb.scene}
        scale={10}
        position={[controlsJet.jetX, 0, -1]}
        rotation={[0, controlsJet.rotationY, 0]}
      /> */}
      {/* <primitive object={parachuteChair.scene} scale={5} position={[0, 0, 0]} /> */}
    </>
  );
};

export default Jet;

useGLTF.preload("/jet/jet3-transformed.glb");
