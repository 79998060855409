import { Cloud, Clouds, useScroll } from "@react-three/drei";
import { useControls } from "leva";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { useRef } from "react";

const CloudsJet = () => {
  const scroll = useScroll();
  const { x, y, z, ...config } = useControls("Clouds", {
    seed: { value: 1, min: 1, max: 100, step: 1 },
    segments: { value: 65, min: 1, max: 80, step: 1 },
    volume: { value: 165, min: 0, max: 300, step: 0.1 },
    opacity: { value: 0.54, min: 0, max: 1, step: 0.01 },
    fade: { value: 218, min: 0, max: 400, step: 1 },
    growth: { value: 8, min: 0, max: 20, step: 1 },
    speed: { value: 0.1, min: 0, max: 1, step: 0.01 },
    x: { value: 43, min: 0, max: 400, step: 1 },
    y: { value: 0, min: 0, max: 100, step: 1 },
    z: { value: 73, min: 0, max: 400, step: 1 },
    color: "white",
  });

  const cloudRefs = useRef([]);

  useFrame(() => {
    const offset = scroll.offset;
    if (offset > 0 && offset <= 0.25) {
      // Puedes ajustar la lógica de movimiento según tus necesidades
      cloudRefs.current.forEach((cloud) => {
        // Mover la nube hacia la izquierda
        cloud.position.x -= 7.6; // 0.76 * 10

        // Si la nube se mueve más allá de -600, reposicionarla aleatoriamente entre 400 y 600
        if (cloud.position.x < -600) {
          cloud.position.x = Math.random() * 200 + 400; // Genera un valor entre 400 y 600
          cloud.position.z = Math.random() * (150 + 350) - 350;
        }
      });
    }
  });

  return (
    <Clouds
      material={THREE.MeshBasicMaterial}
      frustumCulled={false}
      limit={400}
    >
      <Cloud
        ref={(ref) => (cloudRefs.current[0] = ref)}
        {...config}
        bounds={[x, 37, z]}
        position={[15, -60, 0]}
        color="#FFFFFF"
      />
      <Cloud
        ref={(ref) => (cloudRefs.current[1] = ref)}
        {...config}
        bounds={[x, 18, z]}
        color="#FFFFFF"
        position={[-220, -60, 0]}
        segments={25}
      />
      <Cloud
        ref={(ref) => (cloudRefs.current[2] = ref)}
        {...config}
        bounds={[x, 37, z]}
        color="#FFFFFF"
        position={[-340, -60, 0]}
      />
      <Cloud
        ref={(ref) => (cloudRefs.current[3] = ref)}
        {...config}
        bounds={[x, 37, z]}
        position={[15, -60, 150]}
        color="#FFFFFF"
      />
      <Cloud
        ref={(ref) => (cloudRefs.current[4] = ref)}
        {...config}
        bounds={[x, 18, z]}
        color="#FFFFFF"
        position={[-120, -60, 150]}
        segments={25}
      />
      <Cloud
        ref={(ref) => (cloudRefs.current[5] = ref)}
        {...config}
        bounds={[x, 37, z]}
        color="#FFFFFF"
        position={[-240, -60, 150]}
      />
      <Cloud
        ref={(ref) => (cloudRefs.current[6] = ref)}
        {...config}
        bounds={[x, 37, z]}
        position={[-400, -60, 0]}
        color="#FFFFFF"
      />
      <Cloud
        ref={(ref) => (cloudRefs.current[7] = ref)}
        {...config}
        bounds={[x, 18, z]}
        color="#FFFFFF"
        position={[-120, -60, -190]}
        segments={25}
      />
      <Cloud
        ref={(ref) => (cloudRefs.current[8] = ref)}
        {...config}
        bounds={[x, 37, z]}
        color="#FFFFFF"
        position={[-240, -60, -350]}
      />
    </Clouds>
  );
};

export default CloudsJet;
