// import { WorldObject } from "../../object/world-object";
import * as THREE from "three";
import { Scene } from "three";
import { AbstractObject } from "../../object/abstract-object";
// import { PortalWorldObject } from "../../object/portal-world-object";
import { RGBELoader } from "three/addons/loaders/RGBELoader.js";
export class World extends AbstractObject {
  group = new Scene();
  objects = new Set();
  portals = new Map();

  constructor(name, size = Infinity) {
    super();
    this.name = name;
    this.size = size;

    if (name.includes("SkyWorld")) {
      // console.log("this.group2", this.group);
      // this.group.background = new THREE.Color(0x0000ff);
    } else {
      // this.group.background = new THREE.Color(0xcccccc);
    }
  }

  getName() {
    return this.name;
  }

  getSize() {
    return this.size;
  }

  step(delta) {
    for (const object of Array.from(this.objects.values())) {
      object.step(delta);
    }
  }

  addObject(object) {
    if (!this.objects.has(object)) {
      this.objects.add(object);
      this.add(object.getGroup());
    }
  }

  removeObject(object) {
    if (this.objects.has(object)) {
      this.objects.delete(object);
      this.group.remove(object.getGroup());
    }
  }

  addPortal(portal) {
    this.portals.set(portal.getName(), portal);
  }

  getPortals() {
    return Array.from(this.portals.values());
  }

  getPortal(name) {
    return this.portals.get(name);
  }
}
