import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useControls } from "leva";
import EnvironmentWestern from "./WesternComponents/EnvironmentWestern";
import Western from "./WesternComponents/Western";
import InitRenderer from "./PortalComponents/InitRenderer";
import EnvironmentCity from "./CityComponents/EnvironmentCity";
import ModernBlock from "./CityComponents/ModernBlock";
import Ring from "./PortalComponents/Ring/Ring";
import SceneJet from "../SceneJet/SceneJet";
import { Environment, Scroll, ScrollControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { EffectComposer } from "@react-three/postprocessing";
import { LensFlareEffect, LensFlareParams } from "../../lensflare/lensflare";
import Ocean from "./CityComponents/Ocean";
import LightsCity from "./CityComponents/LightsCity";
import { ModernBlock3 } from "./CityComponents/Modern_block3";
const ScenesCityAndPortal = ({ controls }) => {
  const [startAnimationPortal, setStartAnimationPortal] = useState(false);
  const refSceneCity = useRef();
  const refSceneWestern = useRef();
  const get = useThree((state) => state.get);
  const camera = useThree((state) => state.camera);
  const set = useThree((state) => state.set);
  const { scene } = useThree();
  // useEffect(() => {
  //   // set.scene = refSceneCity.current;
  //   set({ scene: refSceneCity.current });
  // }, []);
  // useEffect(() => {
  //   camera.position.set(0, 1000, 10);
  //   camera.far = 3000000;
  //   camera.near = 0.0001;
  //   camera.lookAt(0, 1000, 0);
  // }, [camera]);
  const controlsWestern = useControls("Western", {
    positionX: { value: 52, min: -30, max: 100 },
    positionY: { value: -4.4, min: -30, max: 50 },
    positionZ: { value: 30, min: -100, max: 400 },
  });
  const controlsModern = useControls("Modern", {
    positionX: { value: 78, min: -400, max: 200 },
    positionY: { value: -58, min: -60, max: 20 },
    positionZ: { value: 689, min: 0, max: 700 },
    rotationY: { value: -2.58, min: -Math.PI, max: Math.PI },
  });
  // state.camera.position.set(146, 1510, -52);
  // state.camera.lookAt(-30, 1500, -43);
  const controlsCamera = useControls("Camera", {
    positionX: { value: 146, min: -500, max: 400 },
    positionY: { value: 1510, min: 800, max: 2500 },
    positionZ: { value: -52, min: -500, max: 300 },
    lookX: { value: -30, min: -30, max: 500 },
    lookY: { value: 1500, min: 1000, max: 3000 },
    lookZ: { value: -43, min: -300, max: 300 },
  });
  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "q") {
        setStartAnimationPortal(true);
      }
    });
  }, []);

  useEffect(() => {
    camera.lookAt(
      controlsCamera.lookX,
      controlsCamera.lookY,
      controlsCamera.lookZ
    );
    camera.position.set(
      controlsCamera.positionX,
      controlsCamera.positionY,
      controlsCamera.positionZ
    );
  }, [
    camera,
    controlsCamera.lookX,
    controlsCamera.lookY,
    controlsCamera.lookZ,
    controlsCamera.positionX,
    controlsCamera.positionY,
    controlsCamera.positionZ,
  ]);
  // useEffect(() => {
  //   THREE.ColorManagement.enabled = false;
  //   const lensFlareEffect = LensFlareEffect();
  //   refSceneCity.current.add(lensFlareEffect);
  // }, []);

  return (
    <>
      <InitRenderer
        refSceneWestern={refSceneWestern}
        refSceneCity={refSceneCity}
        startAnimationPortal={startAnimationPortal}
      />

      <scene ref={refSceneWestern}>
        <ambientLight intensity={controls.ambLight} />
        {/* <EnvironmentWestern refSceneWestern={refSceneWestern} /> */}

        {/* <Western controls={controls} controlsWestern={controlsWestern} /> */}
        {/* <mesh>
          <boxGeometry args={[10, 10, 10]} />
          <meshBasicMaterial color="red" />
        </mesh> */}
      </scene>
      <scene ref={refSceneCity} background={"transparent"}>
        {/* <Environment preset="sunset" background /> */}
        <Ring position={[0, 4, 0]} startAnimation={startAnimationPortal} />
        <ambientLight intensity={controls.ambLight} />
        <EnvironmentCity refSceneCity={refSceneCity} />
        <ModernBlock controlsModern={controlsModern} />

        {/* <Ocean /> */}
        <LightsCity refSceneCity={refSceneCity} />
        <ScrollControls pages={1} damping={0.7} distance={90} eps={0.0000001}>
          <Scroll>
            <SceneJet refSceneCity={refSceneCity} />
          </Scroll>
        </ScrollControls>
      </scene>
    </>
  );
};

export default ScenesCityAndPortal;
