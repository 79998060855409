import { useEffect, useRef, useState } from "react";
import Main from "./src/main/main";
import { useFrame, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { animationTimePortal } from "./../../../../constants/globalConstants";

const InitRenderer = ({
  refSceneWestern,
  refSceneCity,
  startAnimationPortal,
}) => {
  const { gl, camera } = useThree();
  const set = useThree((state) => state.set);
  const [portalRoom, setPortalRoom] = useState(null);
  const refInit = useRef(false);
  const refPortalRoom = useRef(null);
  const receiveCamera = (value) => {
    set({ camera: value });
  };
  const receivePortalRoom = (value) => {
    // refPortalRoom.current = value;
    setPortalRoom(value);
  };

  useEffect(() => {
    if (refInit.current) return;
    refInit.current = true;
    const main = new Main(
      gl,
      refSceneWestern,
      refSceneCity,
      receiveCamera,
      receivePortalRoom
    );
    refPortalRoom.current = main.getPortalRoom();
  }, []);

  useEffect(() => {
    console.log("Portal, ", portalRoom);
    if (!portalRoom) return;
    if (!startAnimationPortal) return;

    gsap.to(portalRoom.scale, {
      x: 1,
      y: 1,
      z: 1,
      duration: animationTimePortal,
      ease: "power4.inOut",
    });
  }, [startAnimationPortal]);
  return null;
};

export default InitRenderer;
