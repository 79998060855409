export const TOTAL_ANIMATIONS = 10;
const MULTIPLIER_VELOCITY = 0.1;

export const objectScrollManagment = {
  1: {
    details: "giro de avion 1",
    durationAnimation: 1 * MULTIPLIER_VELOCITY, // Duración estándar (1 unidad de scroll)
    execute: function (fn, offset) {
      const currentAnimation = parseInt(
        Object.keys(objectScrollManagment).find(
          (key) => objectScrollManagment[key] === this
        )
      );
      functionVerifyOffset(fn, currentAnimation, offset);
    },
  },
  2: {
    details: "giro de avion 2",
    durationAnimation: 1 * MULTIPLIER_VELOCITY, // Esta animación dura 3 unidades de scroll
    execute: function (fn, offset) {
      const currentAnimation = parseInt(
        Object.keys(objectScrollManagment).find(
          (key) => objectScrollManagment[key] === this
        )
      );
      functionVerifyOffset(fn, currentAnimation, offset);
    },
  },
  3: {
    details: "giro de avion 3",
    durationAnimation: 2 * MULTIPLIER_VELOCITY,
    execute: function (
      fn,
      offset,
      percentageAnimation = 1,
      startPercentage = 0
    ) {
      const currentAnimation = parseInt(
        Object.keys(objectScrollManagment).find(
          (key) => objectScrollManagment[key] === this
        )
      );
      functionVerifyOffset(
        fn,
        currentAnimation,
        offset,
        percentageAnimation,
        startPercentage
      );
    },
  },
  4: {
    details: "giro de avion 3",
    durationAnimation: 10 * MULTIPLIER_VELOCITY,
    execute: function (fn, offset) {
      const currentAnimation = parseInt(
        Object.keys(objectScrollManagment).find(
          (key) => objectScrollManagment[key] === this
        )
      );
      functionVerifyOffset(fn, currentAnimation, offset);
    },
  },
  10: {
    details: "avion position x para afuera",
    durationAnimation: 1 * MULTIPLIER_VELOCITY,
    execute: function (fn, offset) {
      const currentAnimation = parseInt(
        Object.keys(objectScrollManagment).find(
          (key) => objectScrollManagment[key] === this
        )
      );
      functionVerifyOffset(fn, currentAnimation, offset);
    },
  },
};

// Calcula el total de duración acumulada de las animaciones previas
const getAccumulatedDuration = (currentAnimation) => {
  let accumulatedDuration = 0;
  for (let i = 1; i < currentAnimation; i++) {
    accumulatedDuration += objectScrollManagment[i].durationAnimation;
  }
  return accumulatedDuration;
};

const functionVerifyOffset = (
  fn,
  currentAnimation,
  offset,
  percentageAnimation = 1,
  startPercentage = 0
) => {
  const totalDuration = getAccumulatedDuration(currentAnimation);
  const start =
    (totalDuration +
      startPercentage *
        objectScrollManagment[currentAnimation].durationAnimation) /
    TOTAL_ANIMATIONS;
  const durationAnimation =
    objectScrollManagment[currentAnimation].durationAnimation;
  const end =
    (totalDuration + durationAnimation * percentageAnimation) /
    TOTAL_ANIMATIONS;

  if (offset >= start && offset <= end) {
    const normalizedOffset = (offset - start) / (end - start);
    fn({ normalizedOffset });
  }
};
// const functionVerifyOffset = (
//   fn,
//   currentAnimation,
//   offset,
//   percentageAnimation = 1,
//   startPercentage = 0.5
// ) => {
//   const totalDuration = getAccumulatedDuration(currentAnimation); // Duración acumulada hasta esta animación
//   const start = totalDuration / TOTAL_ANIMATIONS; // Inicio de la animación actual
//   const durationAnimation =
//     objectScrollManagment[currentAnimation].durationAnimation;
//   const end =
//     (totalDuration + durationAnimation * percentageAnimation) /
//     TOTAL_ANIMATIONS;

//   console.log("start", start);
//   console.log("end", end);
//   if (offset >= start && offset <= end) {
//     // Normaliza el offset dentro del rango actual de la animación
//     const normalizedOffset = (offset - start) / (end - start);

//     // El multiplier es el progreso dentro de la animación actual
//     // const multiplier = normalizedOffset;
//     // Redondea el valor de normalizedOffset a intervalos de 0.25
//     // normalizedOffset = Math.round(normalizedOffset * 100) / 100;
//     fn({ normalizedOffset });
//   }
// };
