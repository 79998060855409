import { WorldObject } from "./world-object";
import {
  BoxGeometry,
  CircleGeometry,
  DoubleSide,
  FrontSide,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
} from "three";
// import TWEEN from '@tweenjs/tween.js';

export class PortalWorldObject extends WorldObject {
  mesh;
  destination;

  constructor(name, destinationSceneName, destinationPortalName, size = 7) {
    super();
    this.name = name;
    this.destinationSceneName = destinationSceneName;
    this.destinationPortalName = destinationPortalName;

    // if (!name.includes("SkyWorld")) {
    //   // setTimeout(() => {
    //   this.addPhysicalObject(
    //     (this.mesh = new Mesh(
    //       new CircleGeometry(size, 96),
    //       new MeshBasicMaterial({
    //         side: DoubleSide,
    //         // side: FrontSide,
    //         transparent: true,
    //         opacity: 0.8,
    //       })
    //     ))
    //   );
    //   // }, 3000);
    // } else {
    this.addPhysicalObject(
      (this.mesh = new Mesh(
        new CircleGeometry(size, 96),
        new MeshBasicMaterial({
          // color: "red",
          // side: DoubleSide,
          side: FrontSide,

          transparent: true,
          opacity: 0,
        })
        // new MeshStandardMaterial({
        //   color: "red",
        //   side: FrontSide,
        //   transparent: true,
        //   opacity: 0.5,
        //   emissive: 0x000000, // Ajusta el emisivo si es necesario
        // })
      ))
    );
    this.mesh.rotation.y = Math.PI * 1.25;
    this.mesh.position.y = 0.76;
    this.mesh.scale.set(0, 0, 0);
    // }
    // this.addPhysicalObject(
    //   (this.mesh2 = new Mesh(
    //     new BoxGeometry(0.5, 0.5, 0.5),
    //     new MeshBasicMaterial({
    //       side: DoubleSide,
    //       // side: FrontSide,
    //       color: "red",
    //     })
    //   ))
    // );
    // this.mesh2.position.y = 1;

    // this.startAnimation();
    this.eventListener = this.eventListener.bind(this);
    this.eventListener();
  }
  eventListener() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "1") {
        this.mesh.scale.set(1, 1, 1);
      }
    });
  }
  getDestinationSceneName() {
    return this.destinationSceneName;
  }

  getDestinationPortalName() {
    return this.destinationPortalName;
  }

  setDestination(portal) {
    this.destination = portal;
  }

  getDestination() {
    return this.destination;
  }

  getName() {
    return this.name;
  }

  /*
    startAnimation() {
      const tweenA = new TWEEN.Tween(this.mesh.position)
         .to({
            x: 0,
            y: .4,
            z: 0
         }, 3000)
         .easing(TWEEN.Easing.Sinusoidal.InOut)
         .start();
      const tweenB = new TWEEN.Tween(this.mesh.position)
         .to({
            x: 0,
            y: 0,
            z: 0
         }, 3000)
         .easing(TWEEN.Easing.Sinusoidal.InOut);
      tweenA.chain(tweenB);
      tweenB.chain(tweenA);
   }
    */
}
