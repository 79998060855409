export const vertexShader = `
varying vec2 vUv;
varying float vLife;
uniform float time;

uniform sampler2D uTexture;

void main() {
    
vUv = uv;
vec3 newpos = position;
vec4 simPosition = texture2D( uTexture, vUv );
newpos.xyz = simPosition.xyz;
vLife = simPosition.w;
// newpos.x += 1.;
// newpos.z += sin( time + position.x*10. ) * 0.5;

vec4 mvPosition = modelViewMatrix * vec4( newpos, 1.0 );

// gl_PointSize =  5.*( 2.0 / -mvPosition.z );
gl_PointSize =  4.*( 2.0 / -mvPosition.z );
// gl_PointSize =  1.;

gl_Position = projectionMatrix * mvPosition;

}
`;
