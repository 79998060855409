// import { Inject, Singleton } from "typescript-ioc";
import { Object3D, Raycaster, Vector3 } from "three";
// import { WorldObject } from "../object/world-object";
// import { Collision } from "./physics.model";
// import { SceneComponent } from "../scene/scene.component";

export class PhysicsComponent {
  raycaster = new Raycaster();

  constructor(scene) {
    this.scene = scene;
  }

  // In case of collision, it returns the ratio of "movement" vector to the hit point
  // If the collision is at the third of the "movement" vector, then it will return 0.33
  // If there is no collision, it will return null
  checkCollision(position, movement, objects = []) {
    const vectorMovement = new Vector3();

    // const testMovement = new Vector3(0, 1, 0);
    // movement = testMovement;
    // movement.normalize();

    this.raycaster.set(position, movement);
    this.raycaster.far = movement.length();
    const objectToWorldObject = new Map();
    const physicalObjects = [];
    objects.forEach((object) => {
      object.getPhysicalObjects().forEach((physicalObject) => {
        objectToWorldObject.set(physicalObject, object);
        // console.log("physicalObject", physicalObject);
        // physicalObject.position.z = 0.5;

        physicalObjects.push(physicalObject);
      });
    });

    const intersections = this.raycaster.intersectObjects(
      physicalObjects,
      true
    );

    // if (position.z < 0) {
    // console.log("cambio");

    // return {
    //   ratio: intersection.distance / this.raycaster.far,
    //   object: objectToWorldObject.get(intersection.object),
    //   position: intersection.point,
    // };
    // }a

    if (intersections.length) {
      const intersection = intersections[0];
      // console.log("intersection", intersection);
      // console.log("movement", movement);

      return {
        ratio: intersection.distance / this.raycaster.far,
        object: objectToWorldObject.get(intersection.object),
        position: intersection.point,
      };
    }
    return null;
  }

  checkPortalCollision(position, movement) {
    return this.checkCollision(
      position,
      movement,
      this.scene.getCurrentWorld().getPortals()
    );
  }
}
