import { useHelper } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useControls } from "leva";
import { useEffect, useRef } from "react";
import * as THREE from "three";

const LightsCity = ({ refSceneCity }) => {
  const refGroup = useRef();
  const refLight = useRef();
  const refHelper = useRef();
  //   useHelper(showHelper ? refLight : null, DirectionalLightHelper, 10);
  useHelper(refLight, THREE.DirectionalLightHelper, 40);
  const refObjectTarget = useRef(new THREE.Object3D());
  const controlsLight = useControls("Light", {
    dirIntensity: { value: 0, min: 0, max: 10 },
    // colorLight: "#FF0000",
    colorLight: "#FFAA66",
    positionX: { value: -3800, min: -6000, max: -2500 },
    positionY: { value: 340, min: 0, max: 2000 },
    positionZ: { value: -1490, min: -3000, max: -500 },
    targetX: { value: -40, min: -3000, max: 5000 },
    targetY: { value: 1480, min: 1000, max: 2500 },
    targetZ: { value: 0, min: -300, max: 300 },
    intensity: { value: 10, min: 0, max: 20 },
  });
  //   useHelper(refLight1, DirectionalLightHelper, 100);
  useEffect(() => {
    // refSceneCity.current.add(refObjectTarget.current);
    // refObjectTarget.current.position.set(1000, 1480, 0);
    refLight.current = new THREE.DirectionalLight(0xffaa66, 1);
    refLight.current.position.set(0, 1000, 0);
    refLight.current.target.position.set(-40, 1480, 0);
    refLight.current.target = refObjectTarget.current;

    // refGroup.current.add(refLight.current);
    refSceneCity.current.add(refLight.current);

    refHelper.current = new THREE.DirectionalLightHelper(refLight.current, 80);
    // refGroup.current.add(helper);
    refSceneCity.current.add(refHelper.current);
    // helper.visible = false;
  }, []);
  useEffect(() => {
    if (!refLight.current) return;
    refLight.current.intensity = controlsLight.dirIntensity;
    refLight.current.color = new THREE.Color(controlsLight.colorLight);
    refLight.current.position.set(
      controlsLight.positionX,
      controlsLight.positionY,
      controlsLight.positionZ
    );
  }, [
    controlsLight.dirIntensity,
    controlsLight.colorLight,
    controlsLight.positionX,
    controlsLight.positionY,
    controlsLight.positionZ,
  ]);
  useEffect(() => {
    if (!refLight.current) return;
    if (!refObjectTarget.current) return;

    refObjectTarget.current.position.set(
      controlsLight.targetX,
      controlsLight.targetY,
      controlsLight.targetZ
    );
    // refLight.current.target.position.set(
    //   controlsLight.targetX,
    //   controlsLight.targetY,
    //   controlsLight.targetZ
    // );
    refLight.current.target = refObjectTarget.current;
    refLight.current.target.updateMatrixWorld();
    // refObjectTarget.current.updateMatrixWorld();
  }, [controlsLight.targetX, controlsLight.targetY, controlsLight.targetZ]);

  useEffect(() => {
    if (!refLight.current) return;
    refLight.current.intensity = controlsLight.intensity;
  }, [controlsLight.intensity]);
  useFrame(() => {
    if (refHelper.current) refHelper.current.update();
  });
  return <ambientLight intensity={2.6} />;
  return (
    <>
      {/* <group> */}
      {/* positionX: { value: -3800, min: -6000, max: -2500 },
    positionY: { value: 340, min: 0, max: 2000 },
    positionZ: { value: -1490, min: -3000, max: -500 }, */}
      {/* <directionalLight
          ref={refLight}
          //   position={[
          //     controlsLight.positionX,
          //     controlsLight.positionY,
          //     controlsLight.positionZ,
          //   ]}
          position={[-3800, 340, -1490]}
          target={refObjectTarget.current}
        /> */}
      {/* </group> */}
      <primitive
        object={refObjectTarget.current}
        position={[
          controlsLight.targetX,
          controlsLight.targetY,
          controlsLight.targetZ,
        ]}
      />
      {/* <group ref={refGroup}> */}
      {/* <directionalLight position={[0, 10, 0]} intensity={0.5} /> */}
      {/* </group> */}
    </>
  );
};

export default LightsCity;
